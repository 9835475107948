import React from 'react';
import { NavLink } from 'react-router-dom';
import Cookiebanner from './cookiebanner';

function Footer() {
    const GoToTop = () =>{
        window.scrollTo({top : 0, left : 0, behavior : "smooth"});
    };
    return (<>
        <Cookiebanner isVisible={localStorage.getItem('Cookie_acceptordeny')} />
        <div className="container-fluid p-4 body-font">
            <div className="row" style={{ backgroundColor: 'lightgray' }}>
                <div className="col-xs-12 col-md-5 py-md-5" style={{ textAlign: 'left' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Hertz-247-Horizontal-CMYK 1.png" alt='' />
                    <p>Vous cherchez une location d'utilitaire à l'heure près de chez vous ? Hertz 24/7® propose des locations d'utilitaire à l'heure, disponibles 24 heures sur 24, 7 jours sur 7, en Europe. Nous disposons d'une large sélection de véhicules à louer sur les parkings de nos partenaires.</p>
                </div>
                <div className="col-xs-12 col-md-7 py-md-5" >
                    <div className="row">
                        <div className="col-xs-12 col-md-4 " >
                            <p><b>A propos de Hertz 24/7® </b></p>
                            <p><NavLink className="nav-link" to="/FR/Howitworks" onClick={GoToTop}>Comment cela fonctionne-t-il ?</NavLink></p>
                            <p><NavLink className="nav-link" to="/FR/Locations" onClick={GoToTop}>Localisation des sites</NavLink></p>
                            <p><NavLink className="nav-link" to="/FR/BusinessSolution" onClick={GoToTop}>Solutions pour les entreprises</NavLink></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " >
                            <p><b>Support</b></p>
                            <p><NavLink className="nav-link" to="/UK/FAQ" onClick={GoToTop}>FAQ</NavLink></p>
                            <p><NavLink className="nav-link" to="mailto:hertzfr247@hertz.com">Contact</NavLink></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " >
                            <p><b>Plus d'informations</b></p>                            
                            <p><a className="nav-link" href="https://www.hertz.fr/rentacar/navigation/templates/legalView.jsp" target="_blank" rel='noreferrer'>Conditions d'utilisation</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" style={{ backgroundColor: 'lightgray' }}>
                <span className="border border-2"></span>
                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'left' }}>
                    <b>©2024 Hertz 24/7®  . Tous droits réservés</b>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <div className="row">
                        <div className="col-xs-12 col-md-4">
                            <a href='https://apps.apple.com/fr/app/hertz-24-7-mobility/id6453605078'>
                                <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/AppleAppStore.png" alt='' width={250} />
                            </a>
                        </div>
                        <div className="col-xs-12 col-md-1" >
                            &nbsp;
                        </div>
                        <div className="col-xs-12 col-md-4">
                            <a href='https://play.google.com/store/apps/details?id=com.hertz247.driverApp&hl=fr_fr&gl=fr'>
                                <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/GooglePlayStore.png" alt='' width={250} />
                            </a>
                        </div>
                        <div className="col-xs-12 col-md-3" >
                            <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/QR_Small.png" alt='' />
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <b><NavLink className="nav-link" to="https://www.hertz.fr/rentacar/privacypolicy/index.jsp?targetPage=privacyPolicyView.jsp" target='_blank' rel='noreferrer'>Politique de confidentialité</NavLink></b>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <b><NavLink className="nav-link" to="https://resources.hertz247.ufofleet.com/publishing/documents/247TCUKEN.pdf" target='_blank' rel='noreferrer'>Conditions générales</NavLink></b>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </>);
}
export default Footer;