import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import UKHome from './UK/Home';
import UKBusinessSolution from './UK/BusinessSolution';
import UKFAQ from './UK/FAQ';
import UKHowitWorks from './UK/HowitWorks';
import UKLocations from './UK/Locations';

import DEHome from './DE/Home';
import DEBusinessSolution from './DE/BusinessSolution';
import DEHowitWorks from './DE/HowitWorks';
import DELocations from './DE/Locations';
import DEFAQ from './DE/FAQ';

import PTHome from './PT/Home';
import PTBusinessSolution from './PT/BusinessSolution';
import PTHowitWorks from './PT/HowitWorks';
import PTLocations from './PT/Locations';
import PTFAQ from './PT/FAQ';

import ITHome from './IT/Home';
import ITBusinessSolution from './IT/BusinessSolution';
import ITHowitWorks from './IT/HowitWorks';
import ITLocations from './IT/Locations';
import ITFAQ from './IT/FAQ';

import CZHome from './CZ/Home';
import CZBusinessSolution from './CZ/BusinessSolution';
import CZHowitWorks from './CZ/HowitWorks';
import CZLocations from './CZ/Locations';
import CZFAQ from './CZ/FAQ';

import FRHome from './FR/Home';
import FRBusinessSolution from './FR/BusinessSolution';
import FRHowitWorks from './FR/HowitWorks';
import FRLocations from './FR/Locations';
import FRFAQ from './FR/FAQ';

import LHCarpoolDEHome from './DE/LHCARPOOL/Home';
import LHCarpoolDEHowitWorks from './DE/LHCARPOOL/HowitWorks';
import LHCarpoolDEFAQ from './DE/LHCARPOOL/FAQ';

class Main extends React.Component {
    render() {
        return <BrowserRouter>
            <Routes>
                <Route path="UK/Home" element={<UKHome />} />  
                <Route path="UK/BusinessSolution" element={<UKBusinessSolution />} />  
                <Route path="UK/FAQ" element={<UKFAQ />} />  
                <Route path="UK/Howitworks" element={<UKHowitWorks />} /> 
                <Route path="UK/Locations" element={<UKLocations />} /> 

                <Route path="DE/Home" element={<DEHome />} />            
                <Route path="DE/BusinessSolution" element={<DEBusinessSolution />} />    
                <Route path="DE/Howitworks" element={<DEHowitWorks />} />  
                <Route path="DE/Locations" element={<DELocations />} />  
                <Route path="DE/FAQ" element={<DEFAQ />} />  

                <Route path="PT/Home" element={<PTHome />} />  
                <Route path="PT/BusinessSolution" element={<PTBusinessSolution />} />    
                <Route path="PT/Howitworks" element={<PTHowitWorks />} />  
                <Route path="PT/Locations" element={<PTLocations />} />  
                <Route path="PT/FAQ" element={<PTFAQ />} />    

                <Route path="IT/Home" element={<ITHome />} />  
                <Route path="IT/BusinessSolution" element={<ITBusinessSolution />} />    
                <Route path="IT/Howitworks" element={<ITHowitWorks />} />  
                <Route path="IT/Locations" element={<ITLocations />} />  
                <Route path="IT/FAQ" element={<ITFAQ />} /> 

                <Route path="CZ/Home" element={<CZHome />} />  
                <Route path="CZ/BusinessSolution" element={<CZBusinessSolution />} />    
                <Route path="CZ/Howitworks" element={<CZHowitWorks />} />  
                <Route path="CZ/Locations" element={<CZLocations />} />  
                <Route path="CZ/FAQ" element={<CZFAQ />} /> 

                <Route path="FR/Home" element={<FRHome />} />  
                <Route path="FR/BusinessSolution" element={<FRBusinessSolution />} />    
                <Route path="FR/Howitworks" element={<FRHowitWorks />} />  
                <Route path="FR/Locations" element={<FRLocations />} />  
                <Route path="FR/FAQ" element={<FRFAQ />} /> 

                <Route path="LHCarpool/DE/Home" element={<LHCarpoolDEHome />} />    
                <Route path="LHCarpool/DE/Howitworks" element={<LHCarpoolDEHowitWorks />} />  
                <Route path="LHCarpool/DE/FAQ" element={<LHCarpoolDEFAQ />} /> 
            </Routes>
        </BrowserRouter>
    }
}
export default Main; 