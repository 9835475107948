import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


function Cookiebanner(props) {

    const [show, setShow] = useState(true);

    if (!localStorage.getItem('Cookie_acceptordeny')) {
        localStorage.setItem('Cookie_acceptordeny', null);
        setShow(true);
    }

    if (!localStorage.getItem('country')) {
        localStorage.setItem('country', 'cz');
    }


    const handleAccept = () => {
        setShow(false);
        localStorage.setItem('Cookie_acceptordeny', 'accepted');
    };

    const handleDeny = () => {
        setShow(false);
        localStorage.setItem('Cookie_acceptordeny', 'denied');
    };


    // console.log(props.isVisible);
    if ((props.isVisible === null || props.isVisible === 'null') || localStorage.getItem('country') !== 'cz') {

        localStorage.setItem('country', 'cz');
        return (
            <div>
                <Modal
                    show={show}
                    onHide={handleDeny}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='xl'
                >
                    <Modal.Body>
                        Na našich webových stránkách používáme soubory cookie. Podle svého uvážení můžete kdykoli <b>"Přijmout"</b>, <b>“Odmítnout”</b> nebo změnit nastavení.Výběrem možnosti <b>“Odmítnout”</b> povolujete pouze používání základních souborů cookie, které zajišťují fungování našich stránek. Můžete je zakázat změnou nastavení prohlížeče, což však může ovlivnit fungování webových stránek. Výběrem možnosti<b>"Přijmout"</b>nastavíme všechny nepovinné soubory cookie, které nám pomáhají analyzovat návštěvnost, nabízet rozšířené funkce a také personalizovaný obsah a reklamy.Chcete-li se dozvědět více o souborech cookie, které používáme, a rozhodnout o nastavení nepovinných souborů cookie, vyberte možnost <b>“Spravovat mé soubory cookie”</b>. Informace o tom, jak používáme shromážděné údaje, naleznete na stránce <a href="https://www.hertz.cz/rentacar/privacypolicy/index.jsp?targetPage=privacyPolicyView.jsp" target='_blank' rel='noreferrer'>Zásady ochrany osobních údajů</a>.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="warning" onClick={handleAccept}>
                            Přijmout
                        </Button>
                        <Button variant="warning" onClick={handleDeny}>
                            Odmítnout
                        </Button>
                        {/* <NavLink className="nav-link" to="/UK/FAQ">Manage my Cookies</NavLink> */}
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

export default Cookiebanner;