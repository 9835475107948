import React from 'react';

function BodyHowitWorks() {
    return (<>
        <div className="container-fluid p-4 body-font">
            <div className="row">
                <div className="col-xs-12 col-md-6 py-md-5">
                    <p className="h6"><b>Jak pronájem funguje</b></p>
                    <p className="h1"><i><b>Začít s Hertz 24/7</b></i></p>
                    <p className="h6">Používání služby Hertz 24/7® je snadné. Jednoduše si vytvořte svůj členský profil a získejte přístup ke všem službám Hertz 24/7®.  Nejrychlejší a nejjednodušší způsob, jak to udělat, je naše bezplatná aplikace Hertz 24/7®. </p>
                    <p className="h6">Jednou zadáte své údaje a pomocí naší funkce fotoaparátu v aplikaci, odešlete dokumenty k ověření a můžete začít!</p>
                </div>
                <div className="col-xs-12 col-md-6" style={{ textAlign: 'right' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/howitworks-top.png" alt='' />
                </div>
            </div>

            <div className="row" style={{ backgroundColor: '#F3F3F3' }}>
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <br/>
                    <h1 className="display-5"><b>Jak funguje Hertz 24/7®</b></h1>
                    <br/>
                    <p className="h6">
                        Pronájem s Hertz24/7® je díky nám tak snadný a rychlý, že můžete být ve svém vozidle do 15 minut. Zde je návod, jak to udělat.
                    </p>
                    <br/>
                </div>
            </div>

            <div className="row" style={{ backgroundColor: '#F3F3F3' }}>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/QR_large.png" alt='' />
                    <br/><br/>
                    <p className="h2"><b>01</b></p>
                    <br/>
                    <p className="h2">Stáhněte si aplikaci</p>
                    <p>Aplikaci Hertz 24/7 si snadno a rychle stáhnete z oblíbeného obchodu s aplikacemi, stačí naskenovat QR kód. </p>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Register.png" alt='' />
                    <br/><br/>
                    <p className="h2"><b>02</b></p>
                    <br/>
                    <p className="h2">Registrovat se</p>
                    <p>Jednoduše si vytvořte svůj členský profil a předložit dokumenty k ověření pomocí funkce fotoaparátu v aplikaci.</p>

                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Book_vehicle.png" alt='' />
                    <br/><br/>
                    <p className="h2"><b>03</b></p>
                    <br/>
                    <p className="h2">Rezervujte si vozidlo</p>
                    <p>Přihlaste se do aplikace a rezervujte si vozidlo výběrem požadovaného místa a času vyzvednutí. Budete potřebovat kreditní/debetní kartu.</p>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Step4-Start.png" alt='' />
                    <br/><br/>
                    <p className="h2"><b>04</b></p>
                    <br/>
                    <p className="h2">Zahájení pronájmu</p>
                    <p>Před začátkem pronájmu vám bude přiděleno vozidlo. Po úvodní prohlídce vozidla obdržíte digitální klíč v aplikaci pro odemknutí vozidla.</p>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Step5-During.png" alt='' />
                    <br/><br/>
                    <p className="h2"><b>05</b></p>
                    <br/>
                    <p className="h2">Během pronájmu</p>
                    <p>Pokud během pronájmu potřebujete více času, můžete si pronájem prodloužit. Po celou dobu pronájmu máte také přístup k nájemní smlouvě. Během nečinnosti se může zapnout imobilizér. Pomocí tlačítka v mobilní aplikaci můžete imobilizér deaktivovata.</p>

                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Step6-End.png" alt='' />
                    <br/><br/>
                    <p className="h2"><b>06</b></p>
                    <br/>
                    <p className="h2">Konec pronájmu</p>
                    <p>Po dokončení pronájmu proveďte závěrečnou kontrolu a projděte kontrolní seznam. Do 48 hodin vám bude vystavena faktura a uvolněna blokovaná částka na platební kartě.</p>
                </div>
            </div>


            <div className="row">
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <br/>
                    <h1 className="display-5"><b>Nejčastější dotazy</b></h1>
                    <p className="h6">

                    </p>
                </div>

                <div className="col-xs-12" style={{ textAlign: 'left' }}>
                    <br /><br />

                    <div className="accordion p-4" id="accordionFlushExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading1">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse1" aria-expanded="false" aria-controls="flush-collapse1">
                                    <b>Co je Hertz 24/7?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse1" className="accordion-collapse collapse" aria-labelledby="flush-heading1" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Hertz 24/7® vám poskytuje pohodlí a flexibilitu, protože máte přístup k široké škále dodávek na libovolně dlouhou dobu. Můžete si pronajmout vozidlo na pouhou hodinu nebo až týden. Vyzvednout si je můžete také v kteroukoli denní či noční hodinu. Zaregistrujte se za roční členský poplatek pouhých 5 liber a pak plaťte pouze za pronájmy.<br /> Navíc je v ceně zahrnuto pojištění a údržba. Roční členský poplatek se účtuje při registraci nových členů a přispívá na zajištění bezpečnostní validace na začátku členství a na sanitaci pronajatých vozidel po celou dobu členství. POZNÁMKA: Hertz Zákazníci, kteří jsou platnými členy Costco 24/7, nemusí platit roční členský poplatek.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading2">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2" aria-expanded="false" aria-controls="flush-collapse2">
                                    <b>Jaké údaje požadujete?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse2" className="accordion-collapse collapse" aria-labelledby="flush-heading2" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Chcete-li se připojit ke službě Hertz 24/7, musíte poskytnout následující údaje:<br />
                                    · platný řidičský průkaz na vaše jméno<br />
                                    · Kreditní nebo debetní karta na vaše jméno.<br />
                                    · Vaše číslo mobilního telefonu a e-mailovou adresu.<br />
                                    Před potvrzením registrace provedeme nezbytné kontroly totožnosti.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading3">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3" aria-expanded="false" aria-controls="flush-collapse3">
                                    <b>Jak mi bude účtován poplatek za pronájem Hertz 24/7?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse3" className="accordion-collapse collapse" aria-labelledby="flush-heading3" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Předběžnou autorizaci provedeme až do výše 200 % počátečních poplatků (48) hodin před zahájením pronájmu nebo okamžitě, pokud pronájem začne z méně než 48 hodin. Rezervace bude potvrzena až po úspěšném získání předautorizace. Předautorizace bude uvolněna po vystavení faktury. Odečteme poplatky a rozdíl vrátíme. Pokud náklady na pronájem přesáhnou předautorizovanou částku, nad rámec předautorizace doúčtujeme rozdíl.<br />Vezměte prosím na vědomí, že případné zpoždění vrácení zálohy bude způsobeno bankou, která vydala vaši platební kartu.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading4">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse4" aria-expanded="false" aria-controls="flush-collapse4">
                                    <b>Jak odemknout vozidlo?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse4" className="accordion-collapse collapse" aria-labelledby="flush-heading4" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Aplikace Hertz 24/7 bude vaším digitálním klíčem. Chcete-li odemknout své vozidlo, stiskněte během pronájmu tlačítko "Odemknout" na kartě "CESTA". Poznámka: Vůz budete moci odemknout až po oficiálním začátku pronájmu, dokončení kontroly a podepsání smlouvy.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading5">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse5" aria-expanded="false" aria-controls="flush-collapse5">
                                    <b>Kdo platí náklady na palivo?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse5" className="accordion-collapse collapse" aria-labelledby="flush-heading5" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Pohonné hmoty se účtují za ujeté kilometry, které jsou uvedeny při rezervaci. Všechna vozidla by měla být vybavena palivovou kartou v přihrádce u spolujezdce a na začátku pronájmu by měla mít alespoň čtvrt nádrže paliva. Tímto způsobem byste měli vozidlo také vrátit. Pokud palivová karta na začátku pronájmu chybí, kontaktujte nás na telefonním čísle uvedeném v části "Nápověda" v aplikaci. Palivové karty lze používat na jakékoliv čerpací stanici a při platbě za palivo bude vyžadován pin kód zobrazený v aplikaci.</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </>
    );
}
export default BodyHowitWorks;
