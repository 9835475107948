import React from 'react';
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';
import Cookiebanner from './cookiebanner';

function Footer() {

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/a7df1d20886e43a289ada49553d1e90c706755774a814bb28a800a4fff2f87c9.js";
        script.async = true;      
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    }, []);
    
    const GoToTop = () =>{
        window.scrollTo({top : 0, left : 0, behavior : "smooth"});
    };
    return (<>
        <Cookiebanner isVisible={localStorage.getItem('Cookie_acceptordeny')} />
        <div className="container-fluid p-4 body-font">
            <div className="row" style={{ backgroundColor: 'lightgray' }}>
                <div className="col-xs-12 col-md-5 py-md-5" style={{ textAlign: 'left' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Hertz-247-Horizontal-CMYK 1.png" alt='' />
                    <p>Sie suchen eine stundenweise Anmietung von Transportern an einem Standort in Ihrer Nähe? <br /> Hertz 24/7® bietet stundenweise Anmietung von Transportern, 24 Stunden am Tag, 7 Tage <br /> die Woche an. Wir haben eine große Auswahl an Transportern, die Sie auf den Parkplätzen <br /> unserer Partner wie z.B. Hagebau, IKEA, POCO oder ROLLER mieten können.</p>
                </div>
                <div className="col-xs-12 col-md-7 py-md-5" >
                    <div className="row">
                        <div className="col-xs-12 col-md-4 " >
                            <p><b>Über Hertz 24/7® </b></p>
                            <p><NavLink className="nav-link" to="/DE/Howitworks" onClick={GoToTop}>Wie es funktioniert</NavLink></p>
                            <p><NavLink className="nav-link" to="/DE/Locations" onClick={GoToTop}>Standorte</NavLink></p>
                            <p><NavLink className="nav-link" to="/DE/BusinessSolution" onClick={GoToTop}>Business-Lösungen</NavLink></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " >
                            <p><b>Hilfe</b></p>
                            <p><NavLink className="nav-link" to="/DE/FAQ" onClick={GoToTop}>FAQ</NavLink></p>
                            <p><NavLink className="nav-link" to="mailto:Hertz247GE@hertz.com">Kontakt</NavLink></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " >
                            <p><b>Mehr</b></p>                            
                            <p><a className="nav-link" href="https://www.hertz.de/rentacar/navigation/templates/legalView.jsp" target="_blank" rel='noreferrer'>Nutzungsbedingungen</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" style={{ backgroundColor: 'lightgray' }}>
                <span className="border border-2"></span>
                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'left' }}>
                    <b>©2024 Hertz 24/7®  . Alle Rechte vorbehalten</b>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <div className="row">
                        <div className="col-xs-12 col-md-4">
                            <a href='https://apps.apple.com/de/app/hertz-24-7-mobility/id6453605078'>
                                <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/AppleAppStore.png" alt='' width={250} />
                            </a>
                        </div>
                        <div className="col-xs-12 col-md-1" >
                            &nbsp;
                        </div>
                        <div className="col-xs-12 col-md-4">
                            <a href='https://play.google.com/store/apps/details?id=com.hertz247.driverApp&hl=de_de&gl=de'>
                                <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/GooglePlayStore.png" alt='' width={250} />
                            </a>
                        </div>
                        <div className="col-xs-12 col-md-3" >
                            <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/QR_Small.png" alt='' />
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <b><NavLink className="nav-link" to="https://www.hertz.de/rentacar/privacypolicy/index.jsp?targetPage=privacyPolicyView.jsp" target='_blank' rel='noreferrer'>Datenschutz</NavLink></b>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <b><NavLink className="nav-link" to="https://resources.hertz247.ufofleet.com/publishing/documents/247TCDEDE052024.pdf" target='_blank' rel='noreferrer'>Allgemeine Geschäftsbedingungen</NavLink></b>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </>);
}
export default Footer;