import React from 'react';
import { NavLink } from 'react-router-dom';
import Cookiebanner from './cookiebanner';

function Footer() {
    const GoToTop = () =>{
        window.scrollTo({top : 0, left : 0, behavior : "smooth"});
    };
    return (<>
        <Cookiebanner isVisible={localStorage.getItem('Cookie_acceptordeny')} />
        <div className="container-fluid p-4 body-font">
            <div className="row" style={{ backgroundColor: 'lightgray' }}>
                <div className="col-xs-12 col-md-5 py-md-5" style={{ textAlign: 'left' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Hertz-247-Horizontal-CMYK 1.png" alt='' />
                    <p>Cercate un noleggio di veicoli a ore in località convenienti vicino a voi?<br/> Hertz 24/7® offre il  noleggio di veicoli a ore, disponibili 24 ore <br/>su 24, 7 giorni su 7, in Europa. Abbiamo un'ampia scelta di veicoli da<br/> noleggiare presso i parcheggi dei nostri partner commerciali <br/>come Ikea, Leroy Merlin e Tecnomat.</p>
                </div>
                <div className="col-xs-12 col-md-7 py-md-5" >
                    <div className="row">
                        <div className="col-xs-12 col-md-4 " >
                            <p><b>Informazioni su Hertz 24/7®  </b></p>
                            <p><NavLink className="nav-link" to="/IT/Howitworks" onClick={GoToTop}>Come funziona</NavLink></p>
                            <p><NavLink className="nav-link" to="/IT/Locations" onClick={GoToTop}>Location</NavLink></p>
                            <p><NavLink className="nav-link" to="/IT/BusinessSolution" onClick={GoToTop}>Soluzioni aziendali</NavLink></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " >
                            <p><b>Supporto</b></p>
                            <p><NavLink className="nav-link" to="/IT/FAQ" onClick={GoToTop}>Domande frequenti</NavLink></p>
                            <p><NavLink className="nav-link" to="mailto:hertzit247@hertz.com">Contatti</NavLink></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " >
                            <p><b>Di più</b></p>                            
                            <p><a className="nav-link" href="https://www.hertz.it/rentacar/navigation/templates/legalView.jsp" target="_blank" rel='noreferrer'>Condizioni di utilizzo</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" style={{ backgroundColor: 'lightgray' }}>
                <span className="border border-2"></span>
                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'left' }}>
                    <b>©2024 Hertz 24/7®  . Tutti i diritti riservati</b>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <div className="row">
                        <div className="col-xs-12 col-md-4">
                            <a href='https://apps.apple.com/it/app/hertz-24-7-mobility/id6453605078'>
                                <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/AppleAppStore.png" alt='' width={250} />
                            </a>
                        </div>
                        <div className="col-xs-12 col-md-1" >
                            &nbsp;
                        </div>
                        <div className="col-xs-12 col-md-4">
                            <a href='https://play.google.com/store/apps/details?id=com.hertz247.driverApp&hl=it_it&gl=it'>
                                <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/GooglePlayStore.png" alt='' width={250} />
                            </a>
                        </div>
                        <div className="col-xs-12 col-md-3" >
                            <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/QR_Small.png" alt='' />
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <b><NavLink className="nav-link" to="https://www.hertz.it/rentacar/privacypolicy/index.jsp?targetPage=privacyPolicyView.jsp" target='_blank' rel='noreferrer'>Privacy e politica aziendale</NavLink></b>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <b><NavLink className="nav-link" to="https://resources.hertz247.ufofleet.com/publishing/documents/247TCITIT.pdf" target='_blank' rel='noreferrer'>Termini e condizioni</NavLink></b>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </>);
}
export default Footer;