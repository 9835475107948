import React from 'react';


function FAQBody() {
    return (<>
        <div className="container-fluid  p-4 body-font">
            <div className='row p-4' style={{  width: '100vw', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                <div className="row img-fluid" style={{ backgroundImage: 'url(https://images.hertz.com/content/dam/hod/images/en-gb/static/FAQ_top.png)', backgroundSize: 'cover' }}>
                    <div className="col-xs-12 col-md-1">
                    </div>
                    <div className="col-xs-12 col-md-5">
                        <br />
                        <p className="h6"><b>Domande frequenti</b></p>
                        <p className='h1'><b>Per saperne di più</b></p>
                        <p>
                        Siamo consapevoli che potreste avere domande sul funzionamento di Hertz 24/7, dubbi sulle vostre fatture o semplicemente vorreste sapere cosa è necessario per registrarsi. Questa pagina risponderà a queste domande.
                        </p>



                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                    <div className="col-xs-12 col-md-4">
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <br /><br />
                   
                    <p><a href="#reg" style={{textDecoration : 'none'}}>Registrazione</a></p>
                    <hr />
                    <p><a href="#book" style={{textDecoration : 'none'}}>Prenotazione</a></p>
                    <hr />
                    <p><a href="#yj" style={{textDecoration : 'none'}}>Il vostro viaggio</a></p>
                    <hr />
                    <p><a href="#bfi" style={{textDecoration : 'none'}}>Fatturazione, tariffe e assicurazione</a></p>
                    <hr />
                </div>

                <div className="col-xs-12 col-md-9" style={{ textAlign: 'left' }}>
                    <br /><br />
                    

                    <div className="accordion p-4" id="accordionFlushExample">
                        <br />
                        <p id="reg"><b>Registrazione</b></p>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading1">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse1" aria-expanded="false" aria-controls="flush-collapse1">
                                    <b>Che cos'è Hertz 24/7?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse1" className="accordion-collapse collapse" aria-labelledby="flush-heading1" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Hertz 24/7® vi offre la comodità e la flessibilità di avere accesso a un'ampia gamma di veicoli, per il tempo che desiderate. Potete noleggiare da un'ora fino a una settimana. È inoltre possibile ritirare i veicoli a qualsiasi ora del giorno o della notte. Inoltre, l'assicurazione e la manutenzione sono incluse nella tariffa. </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading2">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2" aria-expanded="false" aria-controls="flush-collapse2">
                                    <b>Di quali dettagli avete bisogno?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse2" className="accordion-collapse collapse" aria-labelledby="flush-heading2" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Per iscriversi a Hertz 24/7 è necessario fornire i seguenti dati:<br />
                                    · Una patente di guida valida a proprio nome<br />
                                    · Una carta di credito o di debito a proprio nome<br />
                                    · Il vostro numero di cellulare e il vostro indirizzo e-mail personale.<br />
                                    Prima di confermare l'iscrizione, effettuiamo i necessari controlli dei documenti d'identità.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading3">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3" aria-expanded="false" aria-controls="flush-collapse3">
                                    <b>Come faccio a sapere se la mia iscrizione è stata accettata?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse3" className="accordion-collapse collapse" aria-labelledby="flush-heading3" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Vi invieremo un'e-mail o un messaggio per confermare che abbiamo ricevuto i vostri documenti di registrazione e vi contatteremo una volta che la vostra domanda sarà stata elaborata.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading4">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse4" aria-expanded="false" aria-controls="flush-collapse4">
                                    <b>Cosa succede se i miei dati di contatto cambiano?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse4" className="accordion-collapse collapse" aria-labelledby="flush-heading4" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Basta accedere al proprio profilo Hertz 24/7 nell'app e modificare i propri dati.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading5">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse5" aria-expanded="false" aria-controls="flush-collapse5">
                                    <b>La mia iscrizione scadrà?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse5" className="accordion-collapse collapse" aria-labelledby="flush-heading5" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">La tua iscrizione è illimitata finché la tua patente di guida è valida e continui a soddisfare le nostre condizioni di noleggio. Potrebbero applicarsi altre condizioni</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading7">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse7" aria-expanded="false" aria-controls="flush-collapse7">
                                    <b>Quanti anni bisogna avere per noleggiare un veicolo?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse7" className="accordion-collapse collapse" aria-labelledby="flush-heading7" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Per noleggiare e guidare uno dei nostri veicoli è necessario aver compiuto 23 anni. È inoltre necessario essere in possesso di una patente di guida completa da almeno due anni.</div>
                            </div>
                        </div>

                        <br />
                        <p id="book"><b>Prenotazione</b></p>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading9">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse9" aria-expanded="false" aria-controls="flush-collapse9">
                                    <b>E se il veicolo che desidero è già prenotato?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse9" className="accordion-collapse collapse" aria-labelledby="flush-heading9" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Se il veicolo che desiderate prenotare non è disponibile presso la vostra sede preferita, scegliete un'altra data o orario, oppure verificare la disponibilità di altre sedi nelle vicinanze.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading10">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse10" aria-expanded="false" aria-controls="flush-collapse10">
                                    <b>Come faccio a sapere se la mia prenotazione è confermata?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse10" className="accordion-collapse collapse" aria-labelledby="flush-heading10" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Vi invieremo la conferma della prenotazione via e-mail e la prenotazione confermata è visibile anche nella sezione Noleggi dell'app.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading11">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse11" aria-expanded="false" aria-controls="flush-collapse11">
                                    <b>Ci sono spese per modificare o cancellare la mia prenotazione?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse11" className="accordion-collapse collapse" aria-labelledby="flush-heading11" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">YÈ possibile modificare o cancellare gratuitamente la prenotazione fino a 6 ore prima dell'orario di ritiro del veicolo. Se il ritiro avviene entro 6 ore, vi verrà addebitato il periodo prenotato, fino a un massimo della tariffa giornaliera.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading12">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse12" aria-expanded="false" aria-controls="flush-collapse12">
                                    <b>Il mio pagamento è sicuro?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse12" className="accordion-collapse collapse" aria-labelledby="flush-heading12" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Sì. Il nostro processore di pagamenti è Stripe. Seguiamo inoltre tutte le leggi sulla protezione di dati per quanto riguarda i dati personali e finanziari.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading13">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse13" aria-expanded="false" aria-controls="flush-collapse13">
                                    <b>Come mi verrà addebitato quando prenoto un noleggio Hertz 24/7?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse13" className="accordion-collapse collapse" aria-labelledby="flush-heading13" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Preautorizzeremo 200€ sulla carta di credito (48) ore prima dell'inizio del noleggio o immediatamente se il noleggio inizia entro 48 ore. La prenotazione sarà confermata solo una volta ottenuta l'autorizzazione. La preautorizzazione verrà rilasciata entro un massimo di 10 giorni dalla data di emissione della fattura. Verranno detratti gli addebiti e restituito il resto. Se il costo del noleggio supera la caparra, l'importo eccedente verrà trattenuto. Si prega di notare che eventuali ritardi nella restituzione del deposito dipendono dalla banca che ha emesso la carta di credito o di debito.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading14">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse14" aria-expanded="false" aria-controls="flush-collapse14">
                                    <b>Con quanto anticipo posso prenotare un veicolo?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse14" className="accordion-collapse collapse" aria-labelledby="flush-heading14" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">A seconda della stazione di noleggio, tra i tre e i sei mesi.</div>
                            </div>
                        </div>

                        <br />
                        <p id="yj"><b>Il vostro viaggio</b></p>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading15">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse15" aria-expanded="false" aria-controls="flush-collapse15">
                                    <b>Come sbloccare il veicolo?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse15" className="accordion-collapse collapse" aria-labelledby="flush-heading15" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">L'app Hertz 24/7 sarà la vostra chiave virtuale. Per aprire l'auto, premere il pulsante "Sblocca" nella sezione di guida durante il noleggio. Nota: non sarà possibile aprire l'auto finché il periodo di noleggio non sarà ufficialmente iniziato, l'ispezione non sarà stata completata e il contratto non sarà stato firmato. Assicurarsi di avere il **Bluetooth** attivato e di trovarsi a meno di 5 metri dal veicolo. **Se si verifica un errore di attivazione del Bluetooth su iOS (&gt; 13) anche se il Bluetooth è attivo, accedere all'applicazione "Impostazioni", quindi alla sezione HERTZ 24/7 e abilitare l'opzione Bluetooth**</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading16">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse16" aria-expanded="false" aria-controls="flush-collapse16">
                                    <b>Dove trovo le chiavi?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse16" className="accordion-collapse collapse" aria-labelledby="flush-heading16" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Le chiavi si trovano all'interno del veicolo nel portaoggetti.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading17">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse17" aria-expanded="false" aria-controls="flush-collapse17">
                                    <b>Come si avvia il veicolo?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse17" className="accordion-collapse collapse" aria-labelledby="flush-heading17" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Nei veicoli con la chiave fisica, la chiave di accensione si trova nel vano portaoggetti. Premere il pedale del freno (pedale del freno e della frizione per il cambio manuale) e premere il pulsante di avviamento o avviare il motore con la chiave. Se il veicolo non si avvia, è possibile che l'immobilizzatore sia attivo. Nell'app Hertz 24/7, nella schermata Guida, premere il pulsante Power per sbloccare l'immobilizzatore. A questo punto il veicolo dovrebbe partire. NOTA: Non c'è un pulsante di avviamento separato su Modelli TESLA e POLESTAR.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading18">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse18" aria-expanded="false" aria-controls="flush-collapse18">
                                    <b>Cosa succede se il veicolo non si avvia?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse18" className="accordion-collapse collapse" aria-labelledby="flush-heading18" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Il veicolo è dotato di una funzione di sicurezza che inserisce l'immobilizzatore se l'accensione rimane spenta per più di 2 minuti. Per riavviare il motore, se immobilizzato, rimuovere la chiave dall'accensione, utilizzare l'app per rimuovere l'immobilizzatore premendo l'icona "power" sulla schermata di guida (l'icona tra le icone di blocco/sblocco). In alternativa, è possibile bloccare e sbloccare il veicolo tramite l'app. Se i l  veicolo continua a non partire, contattate il nostro servizio clienti. Il team risolverà il problema a distanza o trovare un altro veicolo da utilizzare nelle vicinanze.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading19">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse19" aria-expanded="false" aria-controls="flush-collapse19">
                                    <b>Come posso trovare il veicolo?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse19" className="accordion-collapse collapse" aria-labelledby="flush-heading19" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">La conferma della prenotazione include i dettagli su dove trovare il veicolo. Queste informazioni sono disponibili anche online tramite l'app. Prima di prenotare il veicolo, verificare gli orari di apertura del luogo di ritiro e le eventuali limitazioni del parcheggio. Se intendete lasciare il vostro veicolo privato presso la sede, siete pregati di registrarlo presso il personale della sede.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading20">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse20" aria-expanded="false" aria-controls="flush-collapse20">
                                    <b>Cosa succede se il veicolo che ho prenotato non c'è?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse20" className="accordion-collapse collapse" aria-labelledby="flush-heading20" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Assicuratevi di controllare l'area circostante. Se non riuscite ancora a trovare il vostro veicolo, contattate il nostro servizio clienti. Troverete il numero nell'applicazione e vi verrà offerto un veicolo alternativo.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading21">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse21" aria-expanded="false" aria-controls="flush-collapse21">
                                    <b>Cosa succede se restituisco il veicolo in ritardo?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse21" className="accordion-collapse collapse" aria-labelledby="flush-heading21" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Se superate la fascia oraria assegnata senza prolungare il noleggio in anticipo, vi verrà addebitato un importo pari a una tariffa oraria più alta per il tempo supplementare di utilizzo del veicolo, che varia a seconda del veicolo e della località. Verrà inoltre addebitata una tariffa standard per i ritardi.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading22">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse22" aria-expanded="false" aria-controls="flush-collapse22">
                                    <b>È importante se ritiro il veicolo in ritardo?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse22" className="accordion-collapse collapse" aria-labelledby="flush-heading22" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">È possibile ritirare il veicolo in qualsiasi momento durante il periodo di prenotazione. Tuttavia, vi verrà comunque addebitato dall'orario di inizio originario fino al termine del noleggio.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading23">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse23" aria-expanded="false" aria-controls="flush-collapse23">
                                    <b>Chi paga il costo del carburante?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse23" className="accordion-collapse collapse" aria-labelledby="flush-heading23" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Il carburante viene addebitato in centesimi per chilometraggio, indicati al momento della prenotazione. Tutti i veicoli dovrebbero essere dotati di una scheda carburante sotto l'aletta parasole o all'interno del portaoggetti e di almeno un quarto di serbatoio di carburante all'inizio del noleggio. Anche la restituzione del veicolo dovrebbe avvenire in questo modo. Se la carta carburante non è presente all'inizio del noleggio, contattateci utilizzando il numero di telefono presente nella sezione Aiuto dell'app. La carta carburante fornita ha un limite di 50 euro per transazione. Le carte carburante possono essere utilizzate presso le stazioni di rifornimento IP e Total Erg e il codice pin visualizzato nell'app sarà richiesto al momento del pagamento del carburante.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading24">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse24" aria-expanded="false" aria-controls="flush-collapse24">
                                    <b>Cosa succede se il serbatoio del veicolo è vuoto o è pieno per meno di un quarto?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse24" className="accordion-collapse collapse" aria-labelledby="flush-heading24" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Se il serbatoio è pieno per meno di un quarto, vi preghiamo di comunicarcelo e di fare rifornimento utilizzando la carta carburante in dotazione. La scheda carburante si trova sotto l'aletta parasole o all'interno del vano portaoggetti e il codice PIN di accesso alla scheda carburante è disponibile nell'app. Se non c'è carburante nel veicolo o se non riuscite a trovare la carta carburante, contattate il nostro Servizio clienti. Centro.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading25">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse25" aria-expanded="false" aria-controls="flush-collapse25">
                                    <b>Cosa devo fare se ho superato il limite della carta carburante o se ho pagato il carburante senza la carta?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse25" className="accordion-collapse collapse" aria-labelledby="flush-heading25" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Quando si effettua il rifornimento del veicolo, assicurarsi di non superare il limite indicato sulla carta carburante. In caso di superamento del limite, dovrete pagare voi stessi il carburante. Dovrete inviare la ricevuta via e-mail al servizio clienti Hertz 24/7 per ricevere il rimborso del carburante che avete pagato voi stessi.<br/>Si prega di notare che l'elaborazione potrebbe richiedere diversi giorni ed è soggetta a una tassa di elaborazione di 15 euro per contribuire ai costi e alle spese relative al rimborso nel caso in cui non abbiate rispettato la procedura o il corretto processo di rifornimento.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading26">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse26" aria-expanded="false" aria-controls="flush-collapse26">
                                    <b>Posso fumare all'interno del veicolo?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse26" className="accordion-collapse collapse" aria-labelledby="flush-heading26" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">No. Vi preghiamo di non fumare all'interno di qualsiasi veicolo Hertz 24/7. Dal 1° maggio 2013 i nostri veicoli sono stati classificati come aree rigorosamente non fumatori. Questo fa parte del nostro impegno a fornire una flotta sicura e pulita per tutti i nostri dipendenti e clienti. Grazie per la sua considerazione. Se durante il periodo di noleggio dovessimo trovare tracce di fumo all'interno del veicolo, vi   verrà addeitato un costo di pulizia di 50 euro.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading27">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse27" aria-expanded="false" aria-controls="flush-collapse27">
                                    <b>Cosa succede se ricevo una multa per eccesso di velocità o per divieto di sosta?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse27" className="accordion-collapse collapse" aria-labelledby="flush-heading27" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Come ci si aspetterebbe, cose come pedaggi, multe per eccesso di velocità, multe per divieto di sosta e tariffe per i traghetti è vostra responsabilità pagarle.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading28">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse28" aria-expanded="false" aria-controls="flush-collapse28">
                                    <b>Devo pagare una tassa per le zone ZTL?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse28" className="accordion-collapse collapse" aria-labelledby="flush-heading28" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">I nostri mezzi ad eccezione dei veioli elettrici non sono autorizzati ad entrare nelle zone ZTL</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading29">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse29" aria-expanded="false" aria-controls="flush-collapse29">
                                    <b>Cosa succede se trovo qualcosa nel veicolo o se lascio qualcosa di mio?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse29" className="accordion-collapse collapse" aria-labelledby="flush-heading29" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Non è un problema. Contattate il nostro servizio clienti e ci occuperemo di recuperare il vostro articolo.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading30">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse30" aria-expanded="false" aria-controls="flush-collapse30">
                                    <b>Fornite seggiolini per bambini?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse30" className="accordion-collapse collapse" aria-labelledby="flush-heading30" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">No. Tuttavia, siete liberi di usare il vostro.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading31">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse31" aria-expanded="false" aria-controls="flush-collapse31">
                                    <b>Posso trasportare animali domestici nel veicolo?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse31" className="accordion-collapse collapse" aria-labelledby="flush-heading31" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">No. Gli animali domestici non sono ammessi nei nostri veicoli, ad eccezione dei cani guida.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading32">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse32" aria-expanded="false" aria-controls="flush-collapse32">
                                    <b>Devo pulire il veicolo quando lo restituisco?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse32" className="accordion-collapse collapse" aria-labelledby="flush-heading32" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Assicuratevi che l'interno del veicolo sia pulito al termine del noleggio.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading33">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse33" aria-expanded="false" aria-controls="flush-collapse33">
                                    <b>E se il veicolo non è pulito dentro e fuori?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse33" className="accordion-collapse collapse" aria-labelledby="flush-heading33" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Chiediamo ai nostri clienti di lasciare il veicolo pulito e in ordine alla fine di ogni noleggio. Inoltre mantenere la nostra flotta di veicoli su base regolare. Tuttavia, vi preghiamo di farci sapere se non siete soddisfatti con le condizioni del veicolo.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading34">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse34" aria-expanded="false" aria-controls="flush-collapse34">
                                    <b>Posso restituire il veicolo in anticipo?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse34" className="accordion-collapse collapse" aria-labelledby="flush-heading34" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Sì, potete restituire il veicolo in qualsiasi momento durante il periodo di noleggio. Tuttavia, vi verrà comunque addebitato il costro per l'intero periodo di prenotazione.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading35">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse35" aria-expanded="false" aria-controls="flush-collapse35">
                                    <b>Come posso prolungare il mio noleggio?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse35" className="accordion-collapse collapse" aria-labelledby="flush-heading35" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">È possibile estendere facilmente il noleggio tramite l'app, in base alla disponibilità dei veicoli. Seguite i passaggi dopo aver selezionato il pulsante "Estendi noleggio" nella schermata guida dell'app.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading36">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse36" aria-expanded="false" aria-controls="flush-collapse36">
                                    <b>Dove devo restituire il veicolo?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse36" className="accordion-collapse collapse" aria-labelledby="flush-heading36" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Si prega di riconsegnare il veicolo nell'area di parcheggio designata da Hertz 24/7 presso la sede.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading37">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse37" aria-expanded="false" aria-controls="flush-collapse37">
                                    <b>Cosa succede se il mio posto auto designato è già occupato?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse37" className="accordion-collapse collapse" aria-labelledby="flush-heading37" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Si prega di parcheggiare il veicolo nell'area più vicina all'area Hertz 24/7.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading38">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse38" aria-expanded="false" aria-controls="flush-collapse38">
                                    <b>Cosa succede se il veicolo che ho prenotato viene restituito in ritardo dal cliente che mi ha preceduto?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse38" className="accordion-collapse collapse" aria-labelledby="flush-heading38" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Cercheremo sempre di contattarvi in tempo per farvi sapere. Tuttavia, se non avete avuto nostre notizie, vi invitiamo a contattarci al più presto e organizzeremo un veicolo alternativo.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading39">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse39" aria-expanded="false" aria-controls="flush-collapse39">
                                    <b>Non devo mai usare le chiavi per bloccare e sbloccare il veicolo?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse39" className="accordion-collapse collapse" aria-labelledby="flush-heading39" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">NO. Se lo fai, potrebbero sorgere problemi durante il noleggio. Le chiavi devono rimanere sempre nel veicolo. Utilizza l'app Hertz 24/7, per bloccare e sbloccare il veicolo durante il noleggio. NOTA: quando inattivo, l'immobilizzatore del veicolo potrebbe accendersi. Per disattivarlo, premi il pulsante "power" nella schermata "Guida" nell'app.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading40">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse40" aria-expanded="false" aria-controls="flush-collapse40">
                                    <b>Come viene effettuata la manutenzione dei veicoli?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse40" className="accordion-collapse collapse" aria-labelledby="flush-heading40" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Un team di professionisti dedicati si occupa della nostra flotta di veicoli Hertz 24/7. Oltre alla consueta manutenzione programmata, effettuiamo mensilmente un controllo di qualità in 40 punti su ogni veicolo.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading41">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse41" aria-expanded="false" aria-controls="flush-collapse41">
                                    <b>Cosa devo fare in caso di guasto?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse41" className="accordion-collapse collapse" aria-labelledby="flush-heading41" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Chiamate il nostro servizio di assistenza. Il nostro numero di telefono si trova nella pagina dei contatti dell'applicazione. L'assistenza staradale può anche essere contattato direttamente al numero +390645252383</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading42">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse42" aria-expanded="false" aria-controls="flush-collapse42">
                                    <b>Cosa devo fare in caso di incidente?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse42" className="accordion-collapse collapse" aria-labelledby="flush-heading42" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Contattate immediatamente il servizio clienti Hertz 24/7 utilizzando i dettagli di contatto nella sezione "Aiuto" dell'app. Ci occuperemo noi di tutto. Assicuratevi di ottenere i dati della terza parte coinvolta nell'incidente e di scattare foto dei danni subiti da entrambi i veicoli. La mancata denuncia di qualsiasi incidente può invalidare la copertura assicurativa</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading43">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse43" aria-expanded="false" aria-controls="flush-collapse43">
                                    <b>Cosa devo fare se il veicolo viene rubato?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse43" className="accordion-collapse collapse" aria-labelledby="flush-heading43" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">È necessario informarci immediatamente per telefono. Il numero di telefono si trova nella sezione aiuto.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading44">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse44" aria-expanded="false" aria-controls="flush-collapse44">
                                    <b>Posso utilizzare Hertz 24/7 in altri paesi?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse44" className="accordion-collapse collapse" aria-labelledby="flush-heading44" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Sì. Se siete registrati con noi, potete utilizzare Hertz 24 /7 in qualsiasi località aderente all'iniziativa: Italia, Regno Unito, Francia, Germania, Belgio e Portogallo.</div>
                            </div>
                        </div>

                        <br />
                        <p id="bfi"><b>Fatturazione, tariffe e assicurazione</b></p>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading45">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse45" aria-expanded="false" aria-controls="flush-collapse45">
                                    <b>Devo pagare una tassa se il veicolo viene rimosso o bloccato?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse45" className="accordion-collapse collapse" aria-labelledby="flush-heading45" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">La multa verrà inoltrata alle autorità competenti che provvederanno a rinotificarla al vostro indirizzo. Verrà applicata una fee amministrativa di 45 euro.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading46">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse46" aria-expanded="false" aria-controls="flush-collapse46">
                                    <b>Mi verrà chiesto di pagare una tassa di restituzione tardiva se il veicolo si rompe?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse46" className="accordion-collapse collapse" aria-labelledby="flush-heading46" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">No. Nel caso altamente improbabile di un guasto, non sarete tenuti a pagare alcun costo aggiuntivo (a meno che non il guasto non sia stato causato per vostra negligenza). L'assistenza stradale di emergenza 24 ore su 24 è inclusa nella prenotazione.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading47">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse47" aria-expanded="false" aria-controls="flush-collapse47">
                                    <b>Ci sono costi aggiuntivi di cui devo essere a conoscenza?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse47" className="accordion-collapse collapse" aria-labelledby="flush-heading47" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Sì. Ogni prenotazione prevede una tariffa per il chilometraggio. In caso di danni al veicolo, c'è una responmsabilità risarcitoria da pagare. Si tratta di una franchigia fino a 4.000 euro per le auto o di 2.600 euro per i furgoni. La negligenza grave (ad esempio, danni al tetto, contaminazione del carburante) e l'abuso del veicolo non sono coperti dall'esonero dal pagamento dei danni da collisione e dall'assicurazione contro terzi, che sono coperti dalla tariffa di noleggio del veicolo.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading48">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse48" aria-expanded="false" aria-controls="flush-collapse48">
                                    <b>La mia assicurazione auto personale coprirà il costo dei danni?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse48" className="accordion-collapse collapse" aria-labelledby="flush-heading48" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">No, la vostra assicurazione auto personale non è valida per i veicoli Hertz 24/7. I nostri veicoli sono coperti solo dalle polizze assicurative che forniamo o mettiamo a disposizione in relazione alla vostra prenotazione.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading49">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse49" aria-expanded="false" aria-controls="flush-collapse49">
                                    <b>Devo pagare una franchigia?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse49" className="accordion-collapse collapse" aria-labelledby="flush-heading49" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">In caso di danni, smarrimento o furto del veicolo, l'intestatario del noleggio è tenuto a pagare una franchigia fino a 4.000 euro per le autovetture o fino a 2.600€ per i furgoni. Se la riparazione è inferiore alla franchigia, vi rimborseremo la differenza, al netto di una tassa amministrativa.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading50">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse50" aria-expanded="false" aria-controls="flush-collapse50">
                                    <b>Per cosa mi copre l'assicurazione della mia prenotazione?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse50" className="accordion-collapse collapse" aria-labelledby="flush-heading50" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">L'assicurazione copre i danni al veicolo, oltre alla franchigia. La franchigia è il massimo che siete tenuti a pagare in caso di sinistro. Se rispettate i termini e le condizioni del vostro contratto di noleggio, sarete protetti anche dall'assicurazione di responsabilità civile che è inclusa come standard ed è obbligatoria per legge. Questa copre i danni ad altri veicoli, ad altre proprietà e ai feriti in caso di incidente. Se la perdita, il danno o il furto del veicolo sono causati intenzionalmente o se si inserisce nel veicolo il carburante sbagliato (si contamina il serbatoio del carburante o si contamina il serbatoio Adblu), tutti questi danni saranno classificati come negligenza grave e il cliente sarà tenuto a sostenere i costi di riparazione. Se il veicolo viene rubato, vi verrà addebitato solo l'importo della franchigia.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading51">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse51" aria-expanded="false" aria-controls="flush-collapse51">
                                    <b>Posso fare affidamento sul programma di assicurazione del fornitore della mia carta di credito per coprire eventuali danni</b>
                                </button>
                            </h2>
                            <div id="flush-collapse51" className="accordion-collapse collapse" aria-labelledby="flush-heading51" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">È meglio verificare con il fornitore della carta di credito prima di effettuare la prenotazione Hertz 24/7, poiché le diverse carte di credito hanno diversi schemi di protezione assicurativa.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row shadow-lg p-3 mb-5 bg-body rounded" >
                <a href='https:/l.ead.me/beWm77'><img className="img-fluid rounded-4" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/App_Banner-new-format%20IT.png" alt='' style={{ width: '100vw', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} /></a>
            </div>


        </div>


    </>
    );
}

export default FAQBody;