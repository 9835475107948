import React from 'react';
import { NavLink } from 'react-router-dom';
import Cookiebanner from './cookiebanner';



function Footer() {

    const GoToTop = () =>{
        window.scrollTo({top : 0, left : 0, behavior : "smooth"});

    };
  

    return (<>
        <Cookiebanner isVisible={localStorage.getItem('Cookie_acceptordeny')}/>
        <div className="container-fluid p-4 body-font">            
            <div className="row" style={{ backgroundColor: 'lightgray' }}>                
                <div className="col-xs-12 col-md-5 py-md-5" style={{ textAlign: 'left' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Hertz-247-Horizontal-CMYK 1.png" alt='' />
                    <p>Looking for hourly van rental in convenient locations near<br /> you? Hertz 24/7® provides hourly rentals of vans, available<br /> 24 hours a day, 7 days a week, in Europe. We have a large<br /> selection of vans to rent from our retails partners car parks<br /> like B&Q, Costco, Ikea or Homebase..</p>
                </div>
                <div className="col-xs-12 col-md-7 py-md-5" >
                    <div className="row">
                        <div className="col-xs-12 col-md-4 " >
                            <p><b>About Hertz  24/7®  </b></p>
                            <p><NavLink className="nav-link" to="/UK/Howitworks" onClick={GoToTop}>How it works</NavLink></p>
                            <p><NavLink className="nav-link" to="/UK/Locations" onClick={GoToTop}>Locations</NavLink></p>
                            <p><NavLink className="nav-link" to="/UK/BusinessSolution" onClick={GoToTop}>Business Solutions</NavLink></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " >
                            <p><b>Support</b></p>
                            <p><NavLink className="nav-link" to="/UK/FAQ" onClick={GoToTop}>FAQ</NavLink></p>
                            <p><NavLink className="nav-link" to="mailto:hertzuk247@hertz.com">Contact</NavLink></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " >
                            <p><b>More</b></p>                            
                            <p><a className="nav-link" href="https://www.hertz.co.uk/rentacar/navigation/templates/legalView.jsp" target="_blank" rel='noreferrer'>Terms of use</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" style={{ backgroundColor: 'lightgray' }}>
                <span className="border border-2"></span>
                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'left' }}>
                    <b>©2024 Hertz 24/7®  . All rights reserved</b>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <div className="row">
                        <div className="col-xs-12 col-md-4">
                            <a href='https://apps.apple.com/gb/app/hertz-24-7-mobility/id6453605078'>
                                <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/AppleAppStore.png" alt='' width={250} />
                            </a>
                        </div>
                        <div className="col-xs-12 col-md-1" >
                            &nbsp;
                        </div>
                        <div className="col-xs-12 col-md-4">
                            <a href='https://play.google.com/store/apps/details?id=com.hertz247.driverApp&hl=en_GB&gl=GB'>
                                <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/GooglePlayStore.png" alt='' width={250} />
                            </a>
                        </div>
                        <div className="col-xs-12 col-md-3" >
                            <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/QR_Small.png" alt='' />
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <b><NavLink className="nav-link" to="https://www.hertz.co.uk/rentacar/privacypolicy/index.jsp?targetPage=privacyPolicyView.jsp" target='_blank' rel='noreferrer'>Privacy & Policy</NavLink></b>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <b><NavLink className="nav-link" to="https://resources.hertz247.ufofleet.com/publishing/documents/247TCUKEN.pdf" target='_blank' rel='noreferrer'>Terms & Condition</NavLink></b>
                        </div>
                        
                    </div>
                    
                </div>
                
            </div>
        </div>

    </>);
}
export default Footer;