import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import { useState } from "react";

const Map = (props) => {
    const { isLoaded } = props;
    const [selectedMarker, setSelectedMarker] = useState("");
    const containerStyle = {
        width: '100%',
        height: '800px'
    };

    const center = {
        lat: 39.84713947716224,
        lng: -8.04192773234788
    };
    const points = [
        {
            id: 2,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-pt18060600001004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>LEROY MERLIN - Albufeira</a>,
            location: { lat: 37.130415, lng: -8.283398 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-pt18060600001004&product=HOURLY_RENTAL'
        },
        {
            id: 3,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-pt17041200001009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>LEROY MERLIN - Almada</a>,
            location: { lat: 38.65834084, lng: -9.17214716 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-pt17041200001009&product=HOURLY_RENTAL'
        },
        {
            id: 4,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-pt19061900003003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA - Alfragide</a>,
            location: { lat: 38.72875, lng: -9.215722 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-pt19061900003003&product=HOURLY_RENTAL'
        },
        {
            id: 5,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-pt17041200003007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>LEROY MERLIN - Amadora</a>,
            location: { lat: 38.7808787, lng: -9.218889716 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-pt17041200003007&product=HOURLY_RENTAL'
        },
        {
            id: 6,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-pt18061200005008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>LEROY MERLIN - Aveiro </a>,
            location: { lat: 40.6444494, lng: -8.6016737 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-pt18061200005008&product=HOURLY_RENTAL'
        },
        {
            id: 7,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-pt21060700001006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>LEROY MERLIN - Barcelos</a>,
            location: { lat: 41.5488206174964, lng: -8.61474808760612 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-pt21060700001006&product=HOURLY_RENTAL'
        },
        {
            id: 8,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-pt19120600001000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA - Braga</a>,
            location: { lat: 41.5776439, lng: -8.4309309 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-pt19120600001000&product=HOURLY_RENTAL'
        },
        {
            id: 9,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-pt18020600002002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>LEROY MERLIN - Braga</a>,
            location: { lat: 41.54383, lng: -8.398419 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-pt18020600002002&product=HOURLY_RENTAL'
        },
        {
            id: 10,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-pt20022100002007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>LEROY MERLIN - Caldas da Rainha</a>,
            location: { lat: 39.4143814, lng: -9.1472982 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-pt20022100002007&product=HOURLY_RENTAL'
        },
        {
            id: 11,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-pt18012300001000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>LEROY MERLIN - Alfragide</a>,
            location: { lat: 38.724368, lng: -9.215918 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-pt18012300001000&product=HOURLY_RENTAL'
        },
        {
            id: 12,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-pt17041200004006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>LEROY MERLIN - Coimbra</a>,
            location: { lat: 40.19549482, lng: -8.404479542 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-pt17041200004006&product=HOURLY_RENTAL'
        },
        {
            id: 13,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-pt23030100003000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>LEROY MERLIN - Barreiro</a>,
            location: { lat: 38.5828599, lng: -9.043764 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-pt23030100003000&product=HOURLY_RENTAL'
        },
        {
            id: 14,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-pt21040800001009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>LEROY MERLIN - Évora</a>,
            location: { lat: 38.5607687, lng: -7.8950162 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-pt21040800001009&product=HOURLY_RENTAL'
        },
        {
            id: 15,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-pt19081400001002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>LEROY MERLIN - Funchal</a>,
            location: { lat: 32.64669, lng: -16.93966 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-pt19081400001002&product=HOURLY_RENTAL'
        },
        {
            id: 16,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-pt22012000001008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>LEROY MERLIN - Guarda</a>,
            location: { lat: 40.5380973, lng: -7.2397742 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-pt22012000001008&product=HOURLY_RENTAL'
        },
        {
            id: 17,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-pt19032600001007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>LEROY MERLIN - Guimarães </a>,
            location: { lat: 41.438602, lng: -8.33361 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-pt19032600001007&product=HOURLY_RENTAL'
        },
        {
            id: 18,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-pt18091800001003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>LEROY MERLIN - Leiria</a>,
            location: { lat: 39.754446, lng: -8.802076 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-pt18091800001003&product=HOURLY_RENTAL'
        },
        {
            id: 19,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-pt21051200001008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>LEROY MERLIN - Alta de Lisboa</a>,
            location: { lat: 38.7879272, lng: -9.1474945 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-pt21051200001008&product=HOURLY_RENTAL'
        },
        {
            id: 20,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-pt19112100001001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA - Loulé</a>,
            location: { lat: 37.097355, lng: -7.995538 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-pt19112100001001&product=HOURLY_RENTAL'
        },
        {
            id: 21,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-pt18020600001003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>LEROY MERLIN - Loulé</a>,
            location: { lat: 37.1008119, lng: -8.0008518 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-pt18020600001003&product=HOURLY_RENTAL'
        },
        {
            id: 22,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-pt19061900002004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA - Loures</a>,
            location: { lat: 38.817365, lng: -9.148404 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-pt19061900002004&product=HOURLY_RENTAL'
        },
        {
            id: 23,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-pt21022300002001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>LEROY MERLIN - Loures</a>,
            location: { lat: 38.81975, lng: -9.176139 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-pt21022300002001&product=HOURLY_RENTAL'
        },
        {
            id: 24,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-pt17062200005009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>LEROY MERLIN - Maia</a>,
            location: { lat: 41.24316, lng: -8.6267 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-pt17062200005009&product=HOURLY_RENTAL'
        },
        {
            id: 25,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-pt19082900001000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA - Matosinhos</a>,
            location: { lat: 41.209596, lng: -8.68882 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-pt19082900001000&product=HOURLY_RENTAL'
        },
        {
            id: 26,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-pt19052200001001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>LEROY MERLIN - Montijo</a>,
            location: { lat: 38.702576, lng: -8.946056 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-pt19052200001001&product=HOURLY_RENTAL'
        },
        {
            id: 27,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-pt19102400001007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>LEROY MERLIN - Oeiras</a>,
            location: { lat: 38.7048389, lng: -9.305811 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-pt19102400001007&product=HOURLY_RENTAL'
        },
        {
            id: 28,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-pt19061800003005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>LEROY MERLIN - Penafiel</a>,
            location: { lat: 41.192105, lng: -8.304806 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-pt19061800003005&product=HOURLY_RENTAL'
        },
        {
            id: 29,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-pt19061800002006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>LEROY MERLIN - Portimão</a>,
            location: { lat: 37.149277, lng: -8.549678 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-pt19061800002006&product=HOURLY_RENTAL'
        },
        {
            id: 30,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-pt17062200004000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>LEROY MERLIN - Gondomar</a>,
            location: { lat: 41.17392769, lng: -8.566422672 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-pt17062200004000&product=HOURLY_RENTAL'
        },
        {
            id: 31,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-pt17062200006008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>LEROY MERLIN - Matosinhos</a>,
            location: { lat: 41.2119617794949, lng: -8.6854641663131 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-pt17062200006008&product=HOURLY_RENTAL'
        },
        {
            id: 32,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-pt19091200001004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>LEROY MERLIN - Santarém</a>,
            location: { lat: 39.2144023, lng: -8.6923151 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-pt19091200001004&product=HOURLY_RENTAL'
        },
        {
            id: 33,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-pt19061100001002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>LEROY MERLIN - Setúbal</a>,
            location: { lat: 38.538894, lng: -8.874741 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-pt19061100001002&product=HOURLY_RENTAL'
        },
        {
            id: 34,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-pt17062200007007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>LEROY MERLIN - Sintra</a>,
            location: { lat: 38.77938323, lng: -9.35436325 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-pt17062200007007&product=HOURLY_RENTAL'
        },
        {
            id: 35,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-pt18052500003004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>LEROY MERLIN - Torres Vedras</a>,
            location: { lat: 39.097354, lng: -9.2531628 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-pt18052500003004&product=HOURLY_RENTAL'
        },
        {
            id: 36,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-pt21101500002000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>LEROY MERLIN - Viana do Castelo</a>,
            location: { lat: 41.68053, lng: -8.779485 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-pt21101500002000&product=HOURLY_RENTAL'
        },
        {
            id: 37,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-pt17062200003001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>LEROY MERLIN - Gaia</a>,
            location: { lat: 41.1210367, lng: -8.6267566 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-pt17062200003001&product=HOURLY_RENTAL'
        },
        {
            id: 38,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-pt21061600001007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>LEROY MERLIN - Viseu</a>,
            location: { lat: 40.6706037, lng: -7.9182836 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-pt21061600001007&product=HOURLY_RENTAL'
        }
    ];

    return (
        isLoaded && (
            <>
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={7}
                    options={{
                        streetViewControl: false,
                        mapTypeControl: false
                    }}

                >
                    {points.map((point) => {
                        return (
                            <div key={point.id}>
                                <Marker position={point.location} options={{
                                    icon : 'https://images.hertz.com/content/dam/hod/images/en-gb/static/Location_Pin.png',
                                }} onClick={() => {
                                    setSelectedMarker(point);
                                }} />
                            </div>
                        );
                    })}
                    {selectedMarker && (
                        <InfoWindow  position={selectedMarker.location} options={{
                            pixelOffset: new window.google.maps.Size(0, -40)

                        }} onCloseClick={() => setSelectedMarker("")}>
                            <>
                                <h6>{selectedMarker.name}</h6>
                                <button className="btn btn-warning" onClick={() => window.location.href = selectedMarker.ufoUrl}>Reservar agora</button>
                            </>

                        </InfoWindow>
                    )}
                </GoogleMap>
            </>
        )
    );
};

export default Map;