import React from 'react';

function BodyLHHowitWorks() {
    return (<>
        <div className="container-fluid p-4 body-font">
            <div className="row">
                <div className="col-xs-12 col-md-6 py-md-5">
                    <p className="h6"><b>Wie es funktioniert</b></p>
                    <p className="h1"><i><b>Erste Schritte mit Hertz 24/7</b></i></p>
                    <p className="h6">Die Nutzung von Hertz 24/7® ist einfach. Erstellen Sie einfach Ihr Mitgliedsprofil und erhalten Sie Zugang, Berechtigung vorausgesetzt, zum Lufthansa CarPool.</p>
                    <p className="h6">Alles was Sie brauchen ist unsere Hertz 24/7® App. Geben Sie Ihre Daten einmalig ein, senden Sie Ihre Dokumente zur Überprüfung und los geht's!</p>
                </div>
                <div className="col-xs-12 col-md-6" style={{ textAlign: 'right' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/LH_banner3.jpg" alt='' />
                </div>
            </div>

            <div className="row" style={{ backgroundColor: '#F3F3F3' }}>
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <br />
                    <h1 className="display-5"><b>So funktioniert Hertz 24/7®</b></h1>
                    <br />
                    <p className="h6">
                        Wir haben die Anmietung mit Hertz24/7® so einfach und schnell gemacht, dass Sie innerhalb von 15 Minuten in Ihrem Fahrzeug sitzen können. Und so funktioniert es.
                    </p>
                    <br />
                </div>
            </div>

            <div className="row" style={{ backgroundColor: '#F3F3F3' }}>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/QR_large.png" alt='' />
                    <br /><br />
                    <p className="h2"><b>01</b></p>
                    <br />
                    <p className="h2">App herunterladen</p>
                    <p>Laden Sie die neue Hertz 24/7 App schnell und einfach von Ihrem App-Store herunterladen, indem Sie den QR-Code scannen.</p>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Register.png" alt='' />
                    <br /><br />
                    <p className="h2"><b>02</b></p>
                    <br />
                    <p className="h2">Registrierung</p>
                    <p>Erstellen Sie einfach Ihr Mitgliedschaftsprofil und senden Ihre Dokumente zur Überprüfung mit der In-App-Fotofunktion.</p>

                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Select_Location_DE.png" alt='' />
                    <br /><br />
                    <p className="h2"><b>03</b></p>
                    <br />
                    <p className="h2">Fahrzeugbuchung</p>
                    <p>Wählen Sie den gewünschten Abholort, ggf. Abgabeort, und die Abhol- und Abgabezeiten aus. Die Konditionen, wie z.B. die inkludierten Freikilometer, werden Ihnen während der Buchung angezeigt.</p>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Step4-Start.png" alt='' />
                    <br /><br />
                    <p className="h2"><b>04</b></p>
                    <br />
                    <p className="h2">Start Miete</p>
                    <p>Kurz vor Beginn Ihrer Anmietung wird Ihnen das Fahrzeug zugewiesen. Bei der Abholung unterschreiben Sie einfach digtial Ihren Mietvertrag und Sie erhalten den digitalen.Schlüssel in der App, um das Fahrzeug zu entriegeln.</p>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Step5-During.png" alt='' />
                    <br /><br />
                    <p className="h2"><b>05</b></p>
                    <br />
                    <p className="h2">Während Ihrer Miete</p>
                    <p>Wenn Sie während für Ihre Anmietung mehr Zeit benötigen, können Sie Ihren Mietvertrag verlängern. Sie können auch jederzeit Ihren Mietvertrag einsehen. Bei Inaktivität kann die Wegfahrsperre aktiviert werden. Verwenden Sie die Power-Taste in der App, um sie zu deaktivieren.</p>

                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Step6-End.png" alt='' />
                    <br /><br />
                    <p className="h2"><b>06</b></p>
                    <br />
                    <p className="h2">Miete beenden</p>
                    <p>Wenn Sie fertig sind, schließen Sie die Vermietung ab, indem Sie die Schritte der Endkontrolle und die Checkliste durchgehen. Die Rechnung wird Ihnen innerhalb von 48 Stunden per E-Mail zugesendet und die Kaution freigegeben.</p>
                </div>
            </div>


            <div className="row">
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <br />
                    <h1 className="display-5"><b>FAQs</b></h1>
                    <p className="h6">

                    </p>
                </div>

                <div className="col-xs-12" style={{ textAlign: 'left' }}>
                    <br /><br />

                    <div className="accordion p-4" id="accordionFlushExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading1">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse1" aria-expanded="false" aria-controls="flush-collapse1">
                                    <b>Was ist Hertz 24/7?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse1" className="accordion-collapse collapse" aria-labelledby="flush-heading1" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Hertz 24/7® bietet Ihnen den Komfort und die Flexibilität, auf eine breite Palette von Fahrzeugen zugreifen zu können, so lange oder so kurz wie Sie möchten. Sie können von nur einer Stunde bis zu einer Woche mieten. Außerdem können Sie den Wagen zu jeder Tages- und Nachtzeit abholen. Außerdem sind Versicherung und Wartung im Preis inbegriffen. </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading2">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2" aria-expanded="false" aria-controls="flush-collapse2">
                                    <b>Welche Angaben benötigen Sie?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse2" className="accordion-collapse collapse" aria-labelledby="flush-heading2" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Um sich bei Hertz 24/7 anzumelden, müssen Sie die folgenden Angaben machen:<br />
                                    · Ein auf Ihren Namen ausgestellter gültiger Führerschein & Ausweis<br />
                                    · Ein PayPal Konto oder eine  Kredit- oder Debitkarte auf Ihren eigenen Namen<br />
                                    · Ihre eigene Handynummer und Ihre persönliche E-Mail-Adresse.<br />
                                    Wir führen dann die erforderlichen Ausweiskontrollen durch, bevor wir Ihre Anmeldung bestätigen.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading3">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3" aria-expanded="false" aria-controls="flush-collapse3">
                                    <b>Wie werden mir die Kosten berechnet, wenn ich eine Hertz 24/7-Miete buche?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse3" className="accordion-collapse collapse" aria-labelledby="flush-heading3" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">48 Stunden vor Beginn Ihrer Anmietung oder sofort, wenn Ihre Anmietung innerhalb von 48 Stunden beginnt fragen wir eine Genehmigung von bis zu 200% der ursprünglichen Kosten auf Ihrem hinterlegten Zahlungsmittel an. Die Buchung wird erst bestätigt, wenn die Genehmigung erfolgreich erteilt wurde. Die Vorautorisierung wird bei R e c h n u n g s s t e l l u n g freigegeben. Wir ziehen die Gebühren ab und erstatten den Restbetrag. Wenn die Mietkosten die Kaution übersteigen, werden wir den übersteigenden Betrag einbehalten. Bitte beachten Sie, dass jegliche Verzögerung bei der Rückerstattung der Kaution von Ihrer Kredit- oder Debitkarten ausstellenden Bank verursacht wird.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading4">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse4" aria-expanded="false" aria-controls="flush-collapse4">
                                    <b>Wie entriegelt man das Fahrzeug?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse4" className="accordion-collapse collapse" aria-labelledby="flush-heading4" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Die Hertz 24/7-App ist Ihr digitaler Schlüssel. Um Ihr Fahrzeug zu öffnen, drücken Sie während der Anmietung auf der Registerkarte "Fahren" die Schaltfläche "Öffnen". Hinweis: Sie können das Auto erst öffnen, wenn der Mietzeitraum offiziell begonnen hat, die Inspektion abgeschlossen und der Vertrag unterzeichnet wurde.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading5">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse5" aria-expanded="false" aria-controls="flush-collapse5">
                                    <b>Wer kommt für die Treibstoffkosten auf?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse5" className="accordion-collapse collapse" aria-labelledby="flush-heading5" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Kraftstoff ist in unseren Tarifen nicht inkludiert. Das Fahrzeug wird voll übernommen und muss voll abgegeben werden. </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </>
    );
}
export default BodyLHHowitWorks;
