import { useState } from 'react';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { NavLink } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';


function Cookiebanner(props) {

    const [show, setShow] = useState(true);
    const [mcshow, setmcShow] = useState(false);
    const [switchState, setSwitchState] = useState(true);
    const [isSwitchOn, setIsSwitchOn] = useState(true);

    if (!localStorage.getItem('Cookie_acceptordeny')) {
        localStorage.setItem('Cookie_acceptordeny', null);
        setShow(true);
    }

    if (!localStorage.getItem('country'))
    {        
        localStorage.setItem('country', 'pt'); 
    }
  

    const handleAccept = () => {
        setShow(false);
        localStorage.setItem('Cookie_acceptordeny', 'accepted');
        localStorage.setItem('Performance_cookie', true);
    };

    const handleDeny = () => {
        setShow(false);
        localStorage.setItem('Cookie_acceptordeny', 'denied');
        localStorage.setItem('Performance_cookie', false);
    };

    const onSwitchAction = () => {

        if (localStorage.getItem('Performance_cookie') === 'false') {
            setIsSwitchOn(true);
        }

        else
            setIsSwitchOn(false);

    };

    const handleSave = () => {
        localStorage.setItem('Cookie_acceptordeny', 'accepted');
        localStorage.setItem('Performance_cookie', isSwitchOn);
        setShow(false);
        setmcShow(false);
        setSwitchState(true);
    };

    const handleClose = () => setmcShow(false);
    if ((props.isVisible === null || props.isVisible === 'null') || localStorage.getItem('country') !== 'pt')
    {
        localStorage.setItem('country', 'pt');

        return (
            <div>
                <Modal
                    show={show}
                    onHide={handleDeny}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='xl'
                >
                    <Modal.Body>                        
                        Usamos cookies no nosso website. Pode <b>"Aceitar"</b>, <b>"Rejeitar"</b> ou alterar as suas definições a qualquer momento e sempre que queira. Ao escolher <b>"Rejeitar"</b>, apenas aceita a utilização dos cookies essenciais para a correta utilização do nosso website. Poderá desativá-los alterando as definições do seu browser, mas esta ação poderá afetar a forma como o website funciona. Ao escolher <b>"Aceitar"</b>, definiremos todos os cookies opcionais que nos ajudam a analisar o tráfego, oferecer uma funcionalidade aprimorada, bem como apresentar conteúdo personalizado e anúncios. Para saber mais sobre os cookies que utilizamos e decidir as suas definições de cookies opcionais, por favor escolha <b>"Gerir os meus Cookies"</b>. Para mais informação sobre como utilizamos os dados que coletamos, por favor visite a nossa <a href="https://www.hertz.pt/rentacar/privacypolicy/index.jsp?targetPage=privacyPolicyView.jsp" target='_blank' rel='noreferrer'>Política de Privacidade</a>.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="warning" onClick={handleAccept}>
                            Aceitar
                        </Button>
                        <Button variant="warning" onClick={handleDeny}>
                            Rejeitar
                        </Button>
                        <NavLink className="nav-link" onClick={() => setmcShow(true)} style={{ textDecoration: 'none' }}>Gerir os meus Cookies</NavLink> 
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={mcshow}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='xl'
                    onHide={handleClose}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="managecookies-custom-modal-styling-title">
                            <img src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Hertz_247_logo.png" className="img-fluid" alt="Hertz24x7" width={200} /> &nbsp; Gerir Cookies
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        Quando você visita qualquer site, ele pode armazenar ou recuperar informações em seu navegador, principalmente na forma de cookies. Essas informações podem ser sobre você, suas preferências ou seu dispositivo e são usadas principalmente para fazer o site funcionar como você espera. As informações geralmente não o identificam diretamente, mas podem dar-lhe uma experiência web mais personalizada. Uma vez que respeitamos o seu direito à privacidade, pode optar por não permitir alguns tipos de cookies. Clique nos diferentes títulos de categoria para saber mais e alterar as nossas predefinições.
                        <Form>
                            <div className="accordion p-4" id="accordionMgCookie">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-mgc_echeading">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-mgc_eccollapse" aria-expanded="false" aria-controls="flush-mgc_eccollapse">
                                            <b> <Form.Check
                                                disabled
                                                type="switch"
                                                label="Essencial"
                                                id="disabled-custom-switch"
                                                defaultChecked={switchState}
                                            />
                                            </b>
                                        </button>
                                    </h2>
                                    <div id="flush-mgc_eccollapse" className="accordion-collapse collapse" aria-labelledby="flush-mgc_echeading" data-bs-parent="#accordionMgCookie">
                                        Alguns cookies são vitais para o funcionamento de nossos sites. Sem eles, você não seria capaz de navegar pelo site e usar alguns de seus recursos. Os cookies essenciais permitem que as informações que você fornece e as decisões que você toma em relação ao seu aluguel sejam lembradas de página em página no processo de reserva. Como esses cookies são essenciais, não permitimos que você opte por desativá-los em nosso site
                                        <div className="accordion-body">
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Cookie Name</th>
                                                        <th>Description</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>dGroup</td>
                                                        <td>Lembra de qual dispositivo o site está sendo acessado (PC, tablet, telefone) para exibir o conteúdo apropriado.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>incap_ses_*</td>
                                                        <td>Usado para vincular solicitações HTTP a uma sessão.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>nlbi_*</td>
                                                        <td>Este cookie é usado para filtrar solicitações maliciosas. As informações geradas não podem identificá-lo como indivíduo.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td>visid_incap_*</td>
                                                        <td>Usado para verificar se o usuário aceita cookies e não é um robô.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>5</td>
                                                        <td>DC-Cookie</td>
                                                        <td>Usado para encaminhá-lo para o servidor correto durante toda a sua visita.</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-mgcheading2">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-mgccollapse2" aria-expanded="false" aria-controls="flush-mgccollapse2">
                                            <b>
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    label="Cookies de desempenho"
                                                    onChange={onSwitchAction}
                                                    defaultChecked={isSwitchOn}
                                                />
                                            </b>
                                        </button>
                                    </h2>
                                    <div id="flush-mgccollapse2" className="accordion-collapse collapse" aria-labelledby="flush-mgcheading2" data-bs-parent="#accordionMgCookie">
                                        Usamos cookies de desempenho para monitorar o desempenho do site e analisar como nossos visitantes usam nossos sites. Isso nos permite fornecer uma experiência de alta qualidade, identificando e corrigindo rapidamente quaisquer problemas que surjam e personalizando nossa oferta. Todas as informações recolhidas são agregadas e, portanto, anónimas e são apenas utilizadas para melhorar o funcionamento do nosso website.
                                        <div className="accordion-body">NA</div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="warning" onClick={handleSave}>
                            Guardar alterações
                        </Button>

                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

export default Cookiebanner;