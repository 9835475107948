import React from 'react';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Cookiebanner from './cookiebanner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';

function Footer() {

    const [mcshow, setmcShow] = useState(false);
    const [switchState, setSwitchState] = useState(true);
    const [isSwitchOn, setIsSwitchOn] = useState(false);

    const onSwitchAction = () => {

        if (localStorage.getItem('Performance_cookie') === 'false') {
            setIsSwitchOn(true);
        }

        else
            setIsSwitchOn(false);

    };

    const handleSave = () => {
        localStorage.setItem('Performance_cookie', isSwitchOn);
        setSwitchState(true);
        setmcShow(false);
    };

    const handleClose = () => setmcShow(false);

    const GoToTop = () =>{
        window.scrollTo({top : 0, left : 0, behavior : "smooth"});
    };
    return (<>
        <Modal
            show={mcshow}
            backdrop="static"
            keyboard={false}
            centered
            size='xl'
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title id="managecookies-custom-modal-styling-title">
                    <img src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Hertz_247_logo.png" className="img-fluid" alt="Hertz24x7" width={200} /> &nbsp; Spravovat soubory cookie
                </Modal.Title>
            </Modal.Header>
            <Modal.Body >
                Když navštívíte jakoukoli webovou stránku, může ukládat nebo načítat informace ve vašem prohlížeči, většinou ve formě souborů cookie. Tyto informace se mohou týkat vás, vašich preferencí nebo vašeho zařízení a většinou se používají k tomu, aby web fungoval podle očekávání. Tyto informace vás obvykle přímo neidentifikují, ale mohou vám poskytnout personalizovanější webovou zkušenost. Protože respektujeme vaše právo na soukromí, můžete se rozhodnout, že některé typy souborů cookie nepovolíte. Kliknutím na nadpisy různých kategorií se dozvíte více a změníte naše výchozí nastavení.
                <Form>
                    <div className="accordion p-4" id="accordionMgCookie">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-mgc_echeading">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-mgc_eccollapse" aria-expanded="false" aria-controls="flush-mgc_eccollapse">
                                    <b> <Form.Check
                                        disabled
                                        type="switch"
                                        label="Nezbytný"
                                        id="disabled-custom-switch"
                                        defaultChecked={switchState}
                                    />
                                    </b>
                                </button>
                            </h2>
                            <div id="flush-mgc_eccollapse" className="accordion-collapse collapse" aria-labelledby="flush-mgc_echeading" data-bs-parent="#accordionMgCookie">
                                Některé soubory cookie jsou nezbytné pro provoz našich webových stránek. Bez nich byste se nemohli pohybovat po webových stránkách a využívat některé jejich funkce. Základní soubory cookie umožňují, aby si vámi poskytnuté informace a vaše rozhodnutí týkající se pronájmu byly zapamatovány ze stránky na stránku v rezervačním procesu. Protože jsou tyto soubory cookie nezbytné, neumožňujeme vám zvolit si jejich zakázání na našich webových stránkách
                                <div className="accordion-body">
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Cookie</th>
                                                <th>Popis</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>dGroup</td>
                                                <td>Pamatuje si, z jakého zařízení se na stránku přistupuje (PC, tablet, telefon), aby se zobrazil příslušný obsah.</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>incap_ses_*</td>
                                                <td>Slouží k propojení požadavků HTTP s relacemi.</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>nlbi_*</td>
                                                <td>Tento soubor cookie se používá k filtrování škodlivých požadavků. Vygenerované informace vás nemohou identifikovat jako jednotlivce.</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>visid_incap_*</td>
                                                <td>Slouží ke kontrole, zda uživatel přijímá soubory cookie a není robot.</td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>DC-Cookie</td>
                                                <td>Slouží k nasměrování na správný server během celé vaší návštěvy.</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-mgcheading2">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-mgccollapse2" aria-expanded="false" aria-controls="flush-mgccollapse2">
                                    <b>
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            label="Výkonnostní soubory cookie"
                                            onChange={onSwitchAction}
                                            defaultChecked={isSwitchOn}
                                        />
                                    </b>
                                </button>
                            </h2>
                            <div id="flush-mgccollapse2" className="accordion-collapse collapse" aria-labelledby="flush-mgcheading2" data-bs-parent="#accordionMgCookie">
                                Výkonnostní soubory cookie používáme ke sledování výkonu webových stránek a k analýze toho, jak naši návštěvníci používají naše webové stránky. To nám umožňuje poskytovat vysoce kvalitní služby rychlou identifikací a opravou jakýchkoli vzniklých problémů a přizpůsobením naší nabídky. Všechny shromážděné informace jsou agregované, a proto anonymní a používají se pouze ke zlepšení fungování našich webových stránek.
                                <div className="accordion-body">NA</div>
                            </div>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" onClick={handleSave}>
                    Uložit změny
                </Button>

            </Modal.Footer>
        </Modal>

        <Cookiebanner isVisible={localStorage.getItem('Cookie_acceptordeny')} />
        <div className="container-fluid p-4 body-font">
            <div className="row" style={{ backgroundColor: 'lightgray' }}>
                <div className="col-xs-12 col-md-5 py-md-5" style={{ textAlign: 'left' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Hertz-247-Horizontal-CMYK 1.png" alt='' />
                    <p>Hledáte hodinový pronájem dodávek v lokalitách ve vašem okolí? Hertz 24/7® poskytuje hodinový pronájem dodávek, který je v Evropě k dispozici 24 hodin denně, 7 dní v týdnu. Máme velký výběr dodávek k pronájmu na parkovištích našich maloobchodních partnerů, jako například IKEA.</p>
                </div>
                <div className="col-xs-12 col-md-7 py-md-5" >
                    <div className="row">
                        <div className="col-xs-12 col-md-4 " >
                            <p><b>O službě Hertz 24/7® </b></p>
                            <p><NavLink className="nav-link" to="/CZ/Howitworks" onClick={GoToTop}>Jak pronájem funguje</NavLink></p>
                            <p><NavLink className="nav-link" to="/CZ/Locations" onClick={GoToTop}>Lokality</NavLink></p>
                            <p><NavLink className="nav-link" to="/CZ/BusinessSolution" onClick={GoToTop}>Obchodní řešení</NavLink></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " >
                            <p><b>Podpora</b></p>
                            <p><NavLink className="nav-link" to="/CZ/FAQ" onClick={GoToTop}>Nejčastěji kladené otázky</NavLink></p>
                            <p><NavLink className="nav-link" to="mailto:hertz247@hertz.cz">Kontakt</NavLink></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " >
                            <p><b>Více na</b></p>                            
                            <p><a className="nav-link" href="https://www.hertz.cz/rentacar/navigation/templates/legalView.jsp" target="_blank" rel='noreferrer'>Podmínky použití</a></p>
                            <p><NavLink className="nav-link" onClick={() => {
                                setmcShow(true);
                                if (localStorage.getItem('Performance_cookie').toString() === 'false')
                                    setIsSwitchOn(false);
                                else
                                    setIsSwitchOn(true);
                            }} style={{ textDecoration: 'none' }}>Spravovat soubory cookie</NavLink></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" style={{ backgroundColor: 'lightgray' }}>
                <span className="border border-2"></span>
                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'left' }}>
                    <b>©2024 Hertz 24/7®  . Všechna práva vyhrazena</b>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <div className="row">
                        <div className="col-xs-12 col-md-4">
                            <a href='https://apps.apple.com/cz/app/hertz-24-7-mobility/id6453605078'>
                                <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/AppleAppStore.png" alt='' width={250} />
                            </a>
                        </div>
                        <div className="col-xs-12 col-md-1" >
                            &nbsp;
                        </div>
                        <div className="col-xs-12 col-md-4">
                            <a href='https://play.google.com/store/apps/details?id=com.hertz247.driverApp&hl=cs_CZ&gl=CZ'>
                                <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/GooglePlayStore.png" alt='' width={250} />
                            </a>
                        </div>
                        <div className="col-xs-12 col-md-3" >
                            <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/QR_Small.png" alt='' />
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <b><NavLink className="nav-link" to="https://www.hertz.cz/rentacar/privacypolicy/index.jsp?targetPage=privacyPolicyView.jsp" target='_blank' rel='noreferrer'>Ochrana soukromí a zásady</NavLink></b>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <b><NavLink className="nav-link" to="https://resources.hertz247.ufofleet.com/publishing/documents/247CZ.pdf" target='_blank' rel='noreferrer'>Všeobecné obchodní podmínky</NavLink></b>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </>);
}
export default Footer;