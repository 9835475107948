import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


function Cookiebanner(props) {

    const [show, setShow] = useState(true);

    if (!localStorage.getItem('Cookie_acceptordeny')) {
        localStorage.setItem('Cookie_acceptordeny', null);
        setShow(true);
    }

    if (!localStorage.getItem('country')) {
        localStorage.setItem('country', 'uk');
    }

    const handleAccept = () => {
        setShow(false);
        localStorage.setItem('Cookie_acceptordeny', 'accepted');
    };

    const handleDeny = () => {
        setShow(false);
        localStorage.setItem('Cookie_acceptordeny', 'denied');
    };


    // console.log(props.isVisible);
    if ((props.isVisible === null || props.isVisible === 'null') || localStorage.getItem('country') !== 'uk')
    {
        localStorage.setItem('country', 'uk');

        return (
            <div>
                <Modal
                    show={show}
                    onHide={handleDeny}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='xl'
                >
                    <Modal.Body>
                        We use cookies on our website. You can <b>“Accept”</b>, <b>“Decline”</b> or change your setting anytime at your choice. By selecting <b>“Decline”</b> you only allow the use of essential cookies that make our site work. You may disable these by changing your browser settings, but this may affect how the website functions. By selecting <b>“Accept”</b>, we will set all optional cookies that help us analyse traffic, offer enhanced functionality, as well as personalised content and ads.To find out more about the cookies we use and to decide your optional cookies settings, please select <b>“Manage my Cookies”</b>. For information about how we use the data we collect,please visit our <a href="https://www.hertz.co.uk/rentacar/privacypolicy/index.jsp?targetPage=privacyPolicyView.jsp" target='_blank' rel='noreferrer'>Privacy Policy</a>.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="warning" onClick={handleAccept}>
                            Accept Cookies
                        </Button>
                        <Button variant="warning" onClick={handleDeny}>
                            Decline Cookies
                        </Button>
                        {/* <NavLink className="nav-link" to="/UK/FAQ">Manage my Cookies</NavLink> */}
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

export default Cookiebanner;