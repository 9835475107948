import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


function Cookiebanner(props) {

    const [show, setShow] = useState(true);

    if (!localStorage.getItem('Cookie_acceptordeny')) {
        localStorage.setItem('Cookie_acceptordeny', null);
        setShow(true);
    }

    if (!localStorage.getItem('country'))
    {        
        localStorage.setItem('country', 'pt'); 
    }
  

    const handleAccept = () => {
        setShow(false);
        localStorage.setItem('Cookie_acceptordeny', 'accepted');
    };

    const handleDeny = () => {
        setShow(false);
        localStorage.setItem('Cookie_acceptordeny', 'denied');
    }; 

    // console.log(show);
    if ((props.isVisible === null || props.isVisible === 'null') || localStorage.getItem('country') !== 'pt')
    {
        localStorage.setItem('country', 'pt');

        return (
            <div>
                <Modal
                    show={show}
                    onHide={handleDeny}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='xl'
                >
                    <Modal.Body>                        
                        Usamos cookies no nosso website. Pode <b>"Aceitar"</b>, <b>"Rejeitar"</b> ou alterar as suas definições a qualquer momento e sempre que queira. Ao escolher <b>"Rejeitar"</b>, apenas aceita a utilização dos cookies essenciais para a correta utilização do nosso website. Poderá desativá-los alterando as definições do seu browser, mas esta ação poderá afetar a forma como o website funciona. Ao escolher <b>"Aceitar"</b>, definiremos todos os cookies opcionais que nos ajudam a analisar o tráfego, oferecer uma funcionalidade aprimorada, bem como apresentar conteúdo personalizado e anúncios. Para saber mais sobre os cookies que utilizamos e decidir as suas definições de cookies opcionais, por favor escolha <b>"Gerir os meus Cookies"</b>. Para mais informação sobre como utilizamos os dados que coletamos, por favor visite a nossa <a href="https://www.hertz.pt/rentacar/privacypolicy/index.jsp?targetPage=privacyPolicyView.jsp" target='_blank' rel='noreferrer'>Política de Privacidade</a>.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="warning" onClick={handleAccept}>
                            Aceitar
                        </Button>
                        <Button variant="warning" onClick={handleDeny}>
                            Rejeitar
                        </Button>
                        {/* <NavLink className="nav-link" to="/UK/FAQ">Manage my Cookies</NavLink> */}
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

export default Cookiebanner;