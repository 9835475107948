import React from 'react';
import { NavLink } from 'react-router-dom';
import Cookiebanner from './cookiebanner';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';

function Footer() {

    const [mcshow, setmcShow] = useState(false);
    const [switchState, setSwitchState] = useState(true);
    const [isSwitchOn, setIsSwitchOn] = useState(!false);

    const onSwitchAction = () => {

        if (localStorage.getItem('Performance_cookie') === 'false') {
            setIsSwitchOn(true);
        }

        else
            setIsSwitchOn(false);

    };

    const handleSave = () => {
        localStorage.setItem('Performance_cookie', isSwitchOn);
        setSwitchState(true);
        setmcShow(false);
    };

    const handleClose = () => setmcShow(false);

    const GoToTop = () =>{
        window.scrollTo({top : 0, left : 0, behavior : "smooth"});
    };
    return (<>
        
        <Modal
            show={mcshow}
            backdrop="static"
            keyboard={false}
            centered
            size='xl'
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title id="managecookies-custom-modal-styling-title">
                    <img src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Hertz_247_logo.png" className="img-fluid" alt="Hertz24x7" width={200} /> &nbsp; Gérer mes cookies
                </Modal.Title>
            </Modal.Header>
            <Modal.Body >
                Lorsque vous consultez un site Web, des données peuvent être stockées dans votre navigateur ou récupérées à partir de celui-ci, généralement sous la forme de cookies. Ces informations peuvent porter sur vous, sur vos préférences ou sur votre appareil et sont principalement utilisées pour s'assurer que le site Web fonctionne correctement. Les informations ne permettent généralement pas de vous identifier directement, mais peuvent vous permettre de bénéficier d'une expérience Web personnalisée. Parce que nous respectons votre droit à la vie privée, nous vous donnons la possibilité de ne pas autoriser certains types de cookies. Cliquez sur les différentes catégories pour obtenir plus de détails sur chacune d'entre elles, et modifier les paramètres par défaut.
                <Form>
                    <div className="accordion p-4" id="accordionMgCookie">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-mgc_echeading">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-mgc_eccollapse" aria-expanded="false" aria-controls="flush-mgc_eccollapse">
                                    <b> <Form.Check
                                        disabled
                                        type="switch"
                                        label="Essentiel"
                                        id="disabled-custom-switch"
                                        defaultChecked={switchState}
                                    />
                                    </b>
                                </button>
                            </h2>
                            <div id="flush-mgc_eccollapse" className="accordion-collapse collapse" aria-labelledby="flush-mgc_echeading" data-bs-parent="#accordionMgCookie">
                                Certains cookies sont indispensables au fonctionnement de nos sites Internet. Sans eux, vous ne pourriez pas naviguer sur le site et utiliser certaines de ses fonctionnalités. Les cookies essentiels permettent de mémoriser les informations que vous fournissez et les décisions que vous prenez concernant votre location d'une page à l'autre du processus de réservation. Étant donné que ces cookies sont essentiels, nous ne vous permettons pas de choisir de les désactiver sur notre site Internet.
                                <div className="accordion-body">
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Cookie</th>
                                                <th>Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>dGroup</td>
                                                <td>Se souvient de l'appareil à partir duquel le site est consulté (PC, tablette, téléphone) pour afficher le contenu approprié.</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>incap_ses_*</td>
                                                <td>Utilisé pour lier les requêtes HTTP à une session.</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>nlbi_*</td>
                                                <td>Ce cookie est utilisé pour filtrer les requêtes malveillantes. Les informations générées ne permettent pas de vous identifier en tant qu'individu.</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>visid_incap_*</td>
                                                <td>Utilisé pour vérifier que l'utilisateur accepte les cookies et n'est pas un robot.</td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>DC-Cookie</td>
                                                <td>Utilisé pour vous diriger vers le bon serveur pendant toute votre visite.</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-mgcheading2">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-mgccollapse2" aria-expanded="false" aria-controls="flush-mgccollapse2">
                                    <b>
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            label="Cookies de performance"
                                            onChange={onSwitchAction}
                                            defaultChecked={isSwitchOn}
                                        />
                                    </b>
                                </button>
                            </h2>
                            <div id="flush-mgccollapse2" className="accordion-collapse collapse" aria-labelledby="flush-mgcheading2" data-bs-parent="#accordionMgCookie">
                                Nous utilisons des cookies de performance pour surveiller les performances du site Web et analyser la manière dont nos visiteurs utilisent nos sites Web. Cela nous permet de fournir une expérience de haute qualité en identifiant et en résolvant rapidement les problèmes qui surviennent et en personnalisant notre offre. Toutes les informations collectées sont agrégées et donc anonymes et ne sont utilisées que pour améliorer le fonctionnement de notre site Web.
                                <div className="accordion-body">NA</div>
                            </div>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" onClick={handleSave}>
                    Enregistrer les modifications
                </Button>

            </Modal.Footer>
        </Modal>

        <Cookiebanner isVisible={localStorage.getItem('Cookie_acceptordeny')} />
        <div className="container-fluid p-4 body-font">
            <div className="row" style={{ backgroundColor: 'lightgray' }}>
                <div className="col-xs-12 col-md-5 py-md-5" style={{ textAlign: 'left' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Hertz-247-Horizontal-CMYK 1.png" alt='' />
                    <p>Vous cherchez une location d'utilitaire à l'heure près de chez vous ? Hertz 24/7® propose des locations d'utilitaire à l'heure, disponibles 24 heures sur 24, 7 jours sur 7, en Europe. Nous disposons d'une large sélection de véhicules à louer sur les parkings de nos partenaires.</p>
                </div>
                <div className="col-xs-12 col-md-7 py-md-5" >
                    <div className="row">
                        <div className="col-xs-12 col-md-4 " >
                            <p><b>A propos de Hertz 24/7® </b></p>
                            <p><NavLink className="nav-link" to="/FR/Howitworks" onClick={GoToTop}>Comment cela fonctionne-t-il ?</NavLink></p>
                            <p><NavLink className="nav-link" to="/FR/Locations" onClick={GoToTop}>Localisation des sites</NavLink></p>
                            <p><NavLink className="nav-link" to="/FR/BusinessSolution" onClick={GoToTop}>Solutions pour les entreprises</NavLink></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " >
                            <p><b>Support</b></p>
                            <p><NavLink className="nav-link" to="/UK/FAQ" onClick={GoToTop}>FAQ</NavLink></p>
                            <p><NavLink className="nav-link" to="mailto:hertzfr247@hertz.com">Contact</NavLink></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " >
                            <p><b>Plus d'informations</b></p>                            
                            <p><a className="nav-link" href="https://www.hertz.fr/rentacar/navigation/templates/legalView.jsp" target="_blank" rel='noreferrer'>Conditions d'utilisation</a></p>
                            <p><NavLink className="nav-link" onClick={() => {
                                setmcShow(true);
                                if (localStorage.getItem('Performance_cookie').toString() === 'false')
                                    setIsSwitchOn(false);
                                else
                                    setIsSwitchOn(true);
                            }} style={{ textDecoration: 'none' }}>Gérer mes cookies</NavLink></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" style={{ backgroundColor: 'lightgray' }}>
                <span className="border border-2"></span>
                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'left' }}>
                    <b>©2024 Hertz 24/7®  . Tous droits réservés</b>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <div className="row">
                        <div className="col-xs-12 col-md-4">
                            <a href='https://apps.apple.com/fr/app/hertz-24-7-mobility/id6453605078'>
                                <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/AppleAppStore.png" alt='' width={250} />
                            </a>
                        </div>
                        <div className="col-xs-12 col-md-1" >
                            &nbsp;
                        </div>
                        <div className="col-xs-12 col-md-4">
                            <a href='https://play.google.com/store/apps/details?id=com.hertz247.driverApp&hl=fr_fr&gl=fr'>
                                <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/GooglePlayStore.png" alt='' width={250} />
                            </a>
                        </div>
                        <div className="col-xs-12 col-md-3" >
                            <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/QR_Small.png" alt='' />
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <b><NavLink className="nav-link" to="https://www.hertz.fr/rentacar/privacypolicy/index.jsp?targetPage=privacyPolicyView.jsp" target='_blank' rel='noreferrer'>Politique de confidentialité</NavLink></b>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <b><NavLink className="nav-link" to="https://resources.hertz247.ufofleet.com/publishing/documents/247TCUKEN.pdf" target='_blank' rel='noreferrer'>Conditions générales</NavLink></b>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </>);
}
export default Footer;