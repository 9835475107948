import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


function Cookiebanner(props) {

    const [show, setShow] = useState(true);

    if (!localStorage.getItem('Cookie_acceptordeny')) {
        localStorage.setItem('Cookie_acceptordeny', null);
        setShow(true);
    }

    if (!localStorage.getItem('country')) {
        localStorage.setItem('country', 'it');
    }


    const handleAccept = () => {
        setShow(false);
        localStorage.setItem('Cookie_acceptordeny', 'accepted');
    };

    const handleDeny = () => {
        setShow(false);
        localStorage.setItem('Cookie_acceptordeny', 'denied');
    };


    // console.log(props.isVisible);
    if ((props.isVisible === null || props.isVisible === 'null') || localStorage.getItem('country') !== 'it') {

        localStorage.setItem('country', 'it');
        return (
            <div>
                <Modal
                    show={show}
                    onHide={handleDeny}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='xl'
                >
                    <Modal.Body>
                        Su questo sito, usiamo Cookies per offrire il corretto funzionamento e una migliore esperienza di utilizzo. Puoi <b>"Accettare"</b>, <b>"Rifiutare"</b> o modificare le tue scelte in qualsiasi momento. Selezionando <b>"Accetta Cookies"</b> impostiamo cookies opzionali per offrire funzionalità avanzate (ad esempio l'assistenza online e la chat), contenuti e annunci personalizzati, e l'analisi del traffico web. Selezionando <b>"Rifiuta Cookies"</b> consenti solo l'uso dei cookies essenziali per il funzionamento del sito e offrire l'esperienza di navigazione di base. È possibile disabilitarli totalmente modificando le impostazioni del browser, ma ciò potrebbe influire sul funzionamento del sito. Per saperne di più sui cookies che utilizziamo e per decidere/modificare le impostazioni, selezionare <b>"Gestione Cookies"</b>. Per maggiori info su come utilizziamo i dati raccolti, dai un'occhiata alla nostra <a href="https://www.hertz.it/rentacar/privacypolicy/index.jsp?targetPage=privacyPolicyView.jsp" target='_blank' rel='noreferrer'>Politica Privacy</a>.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="warning" onClick={handleAccept}>
                            Accetta Cookies
                        </Button>
                        <Button variant="warning" onClick={handleDeny}>
                            Rifiuta Cookies
                        </Button>
                        {/* <NavLink className="nav-link" to="/UK/FAQ">Manage my Cookies</NavLink> */}
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

export default Cookiebanner;