import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


function Cookiebanner(props) {

    const [show, setShow] = useState(true);

    if (!localStorage.getItem('Cookie_acceptordeny')) {
        localStorage.setItem('Cookie_acceptordeny', null);
        setShow(true);
    }

    if (!localStorage.getItem('country')) {
        localStorage.setItem('country', 'de_lh');
    }

    const handleAccept = () => {
        setShow(false);
        localStorage.setItem('Cookie_acceptordeny', 'accepted');
    };

    const handleDeny = () => {
        setShow(false);
        localStorage.setItem('Cookie_acceptordeny', 'denied');
    };


    // console.log(props.isVisible);
    if ((props.isVisible === null || props.isVisible === 'null') || localStorage.getItem('country') !== 'de_lh') {

        localStorage.setItem('country', 'de_lh');
        return (
            <div>
                <Modal
                    show={show}
                    onHide={handleDeny}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='xl'
                >
                    <Modal.Body>
                        Wir verwenden Cookies auf unserer Website. Sie können diese jederzeit akzeptieren, ablehnen oder Ihre Einstellung ändern. Wenn Sie <b>"Ablehnen"</b> wählen, erlauben Sie nur die Verwendung von wichtigen Cookies, die unsere Website funktionieren lassen. Sie können diese deaktivieren, indem Sie die Einstellungen Ihres Browsers ändern, aber dies kann die Funktionsweise der Website beeinträchtigen. Wenn Sie <b>"Akzeptieren"</b> wählen, setzen wir alle optionalen Cookies, die uns dabei helfen, den Datenverkehr zu analysieren, verbesserte Funktionen anzubieten sowie personalisierte Inhalte und Werbung zu schalten. Um mehr über die von uns verwendeten Cookies zu erfahren und Ihre optionalen Cookie-Einstellungen festzulegen, wählen Sie bitte <b>"Meine Cookies verwalten"</b>. Informationen darüber, wie wir die von uns gesammelten Daten verwenden, finden Sie auf unserer <a href="https://www.hertz.de/rentacar/privacypolicy/index.jsp?targetPage=privacyPolicyView.jsp" target='_blank' rel='noreferrer'>Datenschutzerklärung</a>.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="warning" onClick={handleAccept}>
                            Akzeptieren
                        </Button>
                        <Button variant="warning" onClick={handleDeny}>
                            Ablehnen
                        </Button>
                        {/* <NavLink className="nav-link" to="/UK/FAQ">Manage my Cookies</NavLink> */}
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

export default Cookiebanner;