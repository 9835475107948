import { useState } from 'react';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { NavLink } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';


function Cookiebanner(props) {

    const [show, setShow] = useState(true);
    const [mcshow, setmcShow] = useState(false);
    const [switchState, setSwitchState] = useState(true);
    const [isSwitchOn, setIsSwitchOn] = useState(true);

    if (!localStorage.getItem('Cookie_acceptordeny')) {
        localStorage.setItem('Cookie_acceptordeny', null);
        setShow(true);
    }

    if (!localStorage.getItem('country')) {
        localStorage.setItem('country', 'de');
    }

    const handleAccept = () => {
        setShow(false);
        localStorage.setItem('Cookie_acceptordeny', 'accepted');
        localStorage.setItem('Performance_cookie', true);
    };

    const handleDeny = () => {
        setShow(false);
        localStorage.setItem('Cookie_acceptordeny', 'denied');
        localStorage.setItem('Performance_cookie', false);
    };


    const onSwitchAction = () => {

        if (localStorage.getItem('Performance_cookie') === 'false') {
            setIsSwitchOn(true);
        }

        else
            setIsSwitchOn(false);

    };

    const handleSave = () => {
        localStorage.setItem('Cookie_acceptordeny', 'accepted');
        localStorage.setItem('Performance_cookie', isSwitchOn);
        setShow(false);
        setmcShow(false);
        setSwitchState(true);
    };

    const handleClose = () => setmcShow(false);

    if ((props.isVisible === null || props.isVisible === 'null') || localStorage.getItem('country') !== 'de') {

        localStorage.setItem('country', 'de');
        return (
            <div>
                <Modal
                    show={show}
                    onHide={handleDeny}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='xl'
                >
                    <Modal.Body>
                        Wir verwenden Cookies auf unserer Website. Sie können diese jederzeit akzeptieren, ablehnen oder Ihre Einstellung ändern. Wenn Sie <b>"Ablehnen"</b> wählen, erlauben Sie nur die Verwendung von wichtigen Cookies, die unsere Website funktionieren lassen. Sie können diese deaktivieren, indem Sie die Einstellungen Ihres Browsers ändern, aber dies kann die Funktionsweise der Website beeinträchtigen. Wenn Sie <b>"Akzeptieren"</b> wählen, setzen wir alle optionalen Cookies, die uns dabei helfen, den Datenverkehr zu analysieren, verbesserte Funktionen anzubieten sowie personalisierte Inhalte und Werbung zu schalten. Um mehr über die von uns verwendeten Cookies zu erfahren und Ihre optionalen Cookie-Einstellungen festzulegen, wählen Sie bitte <b>"Meine Cookies verwalten"</b>. Informationen darüber, wie wir die von uns gesammelten Daten verwenden, finden Sie auf unserer <a href="https://www.hertz.de/rentacar/privacypolicy/index.jsp?targetPage=privacyPolicyView.jsp" target='_blank' rel='noreferrer'>Datenschutzerklärung</a>.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="warning" onClick={handleAccept}>
                            Akzeptieren
                        </Button>
                        <Button variant="warning" onClick={handleDeny}>
                            Ablehnen
                        </Button>
                        <NavLink className="nav-link" onClick={() => setmcShow(true)} style={{ textDecoration: 'none' }}>Meine Cookies verwalten</NavLink> 
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={mcshow}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='xl'
                    onHide={handleClose}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="managecookies-custom-modal-styling-title">
                            <img src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Hertz_247_logo.png" className="img-fluid" alt="Hertz24x7" width={200} /> &nbsp; Meine Cookies verwalten
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        Wenn Sie eine Website besuchen, kann sie Informationen in Ihrem Browser speichern oder abrufen, meist in Form von Cookies. Diese Informationen können sich auf Sie, Ihre Präferenzen oder Ihr Gerät beziehen und werden meist dazu verwendet, dass die Website so funktioniert, wie Sie es erwarten. Die Informationen identifizieren Sie normalerweise nicht direkt, aber sie können Ihnen ein personalisierteres Web-Erlebnis bieten. Zum Schutz Ihrer personenbezogenen Daten können Sie festlegen, bestimmte Arten von Cookies nicht zuzulassen. Klicken Sie auf die verschiedenen Kategorien, um mehr darüber zu erfahren und unsere Standardeinstellungen zu ändern.
                        <Form>
                            <div className="accordion p-4" id="accordionMgCookie" style={{ overflow: 'auto' }}>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-mgc_echeading">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-mgc_eccollapse" aria-expanded="false" aria-controls="flush-mgc_eccollapse">
                                            <b> <Form.Check
                                                disabled
                                                type="switch"
                                                label="Essentiell"
                                                id="disabled-custom-switch"
                                                defaultChecked={switchState}
                                            />
                                            </b>
                                        </button>
                                    </h2>

                                    <div id="flush-mgc_eccollapse" className="accordion-collapse collapse" aria-labelledby="flush-mgc_echeading" data-bs-parent="#accordionMgCookie" >
                                        <div className="accordion-body" >
                                            Einige Cookies sind für den Betrieb unserer Websites unerlässlich. Ohne sie könnten Sie sich nicht auf der Website bewegen und einige ihrer Funktionen nicht nutzen. Notwendige Cookies ermöglichen, dass die von Ihnen bereitgestellten Informationen und Ihre Entscheidungen bezüglich Ihrer Anmietung im Buchungsprozess von Seite zu Seite gespeichert werden. Da diese Cookies unerlässlich sind, können Sie sie auf unserer Website nicht deaktivieren.
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Cookie</th>
                                                        <th>Beschreibung</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>dGroup</td>
                                                        <td>Merkt sich, von welchem ​​Gerät aus auf die Site zugegriffen wird (PC, Tablet, Telefon), um den entsprechenden Inhalt anzuzeigen.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>incap_ses_*</td>
                                                        <td>Wird verwendet, um HTTP-Anfragen mit einer Sitzung zu verknüpfen.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>nlbi_*</td>
                                                        <td>Dieses Cookie dient zum Herausfiltern böswilliger Anfragen. Die generierten Informationen können Sie nicht als Einzelperson identifizieren.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td>visid_incap_*</td>
                                                        <td>Wird verwendet, um zu überprüfen, ob der Benutzer Cookies akzeptiert und kein Roboter ist.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>5</td>
                                                        <td>DC-Cookie</td>
                                                        <td>Wird verwendet, um Sie während Ihres gesamten Besuchs zum richtigen Server weiterzuleiten.</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-mgc_pcheading">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-mgc_pccollapse" aria-expanded="false" aria-controls="flush-mgccollapse2">
                                            <b>
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    label="Leistungs-Cookies"
                                                    onChange={onSwitchAction}
                                                    defaultChecked={isSwitchOn}
                                                />
                                            </b>
                                        </button>
                                    </h2>
                                    <div id="flush-mgc_pccollapse" className="accordion-collapse collapse" aria-labelledby="flush-mgc_pcheading" data-bs-parent="#accordionMgCookie">
                                        Wir verwenden Performance-Cookies, um die Leistung der Website zu überwachen und zu analysieren, wie unsere Besucher unsere Websites nutzen. Auf diese Weise können wir ein qualitativ hochwertiges Erlebnis bieten, indem wir auftretende Probleme schnell identifizieren und beheben und unser Angebot anpassen. Alle gesammelten Informationen werden aggregiert und sind daher anonym und werden nur dazu verwendet, die Funktionsweise unserer Website zu verbessern.
                                        <div className="accordion-body">NA</div>
                                    </div>
                                </div>
                            </div>

                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="warning" onClick={handleSave}>
                            Änderungen Speichern
                        </Button>

                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

export default Cookiebanner;