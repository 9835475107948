import { useState } from 'react';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { NavLink } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';


function Cookiebanner(props) {

    const [show, setShow] = useState(true);
    const [mcshow, setmcShow] = useState(false);
    const [switchState, setSwitchState] = useState(true);
    const [isSwitchOn, setIsSwitchOn] = useState(true);

    if (!localStorage.getItem('Cookie_acceptordeny')) {
        localStorage.setItem('Cookie_acceptordeny', null);
        setShow(true);
    }

    if (!localStorage.getItem('country')) {
        localStorage.setItem('country', 'cz');
    }


    const handleAccept = () => {
        setShow(false);
        localStorage.setItem('Cookie_acceptordeny', 'accepted');
        localStorage.setItem('Performance_cookie', true);
    };

    const handleDeny = () => {
        setShow(false);
        localStorage.setItem('Cookie_acceptordeny', 'denied');
        localStorage.setItem('Performance_cookie', false);
    };

    const onSwitchAction = () => {

        if (localStorage.getItem('Performance_cookie') === 'false') {
            setIsSwitchOn(true);
        }

        else
            setIsSwitchOn(false);

    };

    const handleSave = () => {
        localStorage.setItem('Cookie_acceptordeny', 'accepted');
        localStorage.setItem('Performance_cookie', isSwitchOn);
        setShow(false);
        setmcShow(false);
        setSwitchState(true);
    };

    const handleClose = () => setmcShow(false);

    
    if ((props.isVisible === null || props.isVisible === 'null') || localStorage.getItem('country') !== 'cz') {

        localStorage.setItem('country', 'cz');
        return (
            <div>
                <Modal
                    show={show}
                    onHide={handleDeny}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='xl'
                >
                    <Modal.Body>
                        Na našich webových stránkách používáme soubory cookie. Podle svého uvážení můžete kdykoli <b>"Přijmout"</b>, <b>“Odmítnout”</b> nebo změnit nastavení.Výběrem možnosti <b>“Odmítnout”</b> povolujete pouze používání základních souborů cookie, které zajišťují fungování našich stránek. Můžete je zakázat změnou nastavení prohlížeče, což však může ovlivnit fungování webových stránek. Výběrem možnosti<b>"Přijmout"</b>nastavíme všechny nepovinné soubory cookie, které nám pomáhají analyzovat návštěvnost, nabízet rozšířené funkce a také personalizovaný obsah a reklamy.Chcete-li se dozvědět více o souborech cookie, které používáme, a rozhodnout o nastavení nepovinných souborů cookie, vyberte možnost <b>“Spravovat mé soubory cookie”</b>. Informace o tom, jak používáme shromážděné údaje, naleznete na stránce <a href="https://www.hertz.cz/rentacar/privacypolicy/index.jsp?targetPage=privacyPolicyView.jsp" target='_blank' rel='noreferrer'>Zásady ochrany osobních údajů</a>.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="warning" onClick={handleAccept}>
                            Přijmout
                        </Button>
                        <Button variant="warning" onClick={handleDeny}>
                            Odmítnout
                        </Button>
                        <NavLink className="nav-link" onClick={() => setmcShow(true)} style={{ textDecoration: 'none' }}>Spravovat mé soubory cookie</NavLink> 
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={mcshow}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='xl'
                    onHide={handleClose}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="managecookies-custom-modal-styling-title">
                            <img src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Hertz_247_logo.png" className="img-fluid" alt="Hertz24x7" width={200} /> &nbsp;  Spravovat soubory cookie
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        Když navštívíte jakoukoli webovou stránku, může ukládat nebo načítat informace ve vašem prohlížeči, většinou ve formě souborů cookie. Tyto informace se mohou týkat vás, vašich preferencí nebo vašeho zařízení a většinou se používají k tomu, aby web fungoval podle očekávání. Tyto informace vás obvykle přímo neidentifikují, ale mohou vám poskytnout personalizovanější webovou zkušenost. Protože respektujeme vaše právo na soukromí, můžete se rozhodnout, že některé typy souborů cookie nepovolíte. Kliknutím na nadpisy různých kategorií se dozvíte více a změníte naše výchozí nastavení.
                        <Form>
                            <div className="accordion p-4" id="accordionMgCookie">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-mgc_echeading">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-mgc_eccollapse" aria-expanded="false" aria-controls="flush-mgc_eccollapse">
                                            <b> <Form.Check
                                                disabled
                                                type="switch"
                                                label="Nezbytný"
                                                id="disabled-custom-switch"
                                                defaultChecked={switchState}
                                            />
                                            </b>
                                        </button>
                                    </h2>
                                    <div id="flush-mgc_eccollapse" className="accordion-collapse collapse" aria-labelledby="flush-mgc_echeading" data-bs-parent="#accordionMgCookie">
                                        Některé soubory cookie jsou nezbytné pro provoz našich webových stránek. Bez nich byste se nemohli pohybovat po webových stránkách a využívat některé jejich funkce. Základní soubory cookie umožňují, aby si vámi poskytnuté informace a vaše rozhodnutí týkající se pronájmu byly zapamatovány ze stránky na stránku v rezervačním procesu. Protože jsou tyto soubory cookie nezbytné, neumožňujeme vám zvolit si jejich zakázání na našich webových stránkách
                                        <div className="accordion-body">
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Cookie</th>
                                                        <th>Popis</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>dGroup</td>
                                                        <td>Pamatuje si, z jakého zařízení se na stránku přistupuje (PC, tablet, telefon), aby se zobrazil příslušný obsah.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>incap_ses_*</td>
                                                        <td>Slouží k propojení požadavků HTTP s relacemi.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>nlbi_*</td>
                                                        <td>Tento soubor cookie se používá k filtrování škodlivých požadavků. Vygenerované informace vás nemohou identifikovat jako jednotlivce.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td>visid_incap_*</td>
                                                        <td>Slouží ke kontrole, zda uživatel přijímá soubory cookie a není robot.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>5</td>
                                                        <td>DC-Cookie</td>
                                                        <td>Slouží k nasměrování na správný server během celé vaší návštěvy.</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-mgcheading2">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-mgccollapse2" aria-expanded="false" aria-controls="flush-mgccollapse2">
                                            <b>
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    label="Výkonnostní soubory cookie"
                                                    onChange={onSwitchAction}
                                                    defaultChecked={isSwitchOn}
                                                />
                                            </b>
                                        </button>
                                    </h2>
                                    <div id="flush-mgccollapse2" className="accordion-collapse collapse" aria-labelledby="flush-mgcheading2" data-bs-parent="#accordionMgCookie">
                                        Výkonnostní soubory cookie používáme ke sledování výkonu webových stránek a k analýze toho, jak naši návštěvníci používají naše webové stránky. To nám umožňuje poskytovat vysoce kvalitní služby rychlou identifikací a opravou jakýchkoli vzniklých problémů a přizpůsobením naší nabídky. Všechny shromážděné informace jsou agregované, a proto anonymní a používají se pouze ke zlepšení fungování našich webových stránek.
                                        <div className="accordion-body">NA</div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="warning" onClick={handleSave}>
                            Uložit změny
                        </Button>

                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

export default Cookiebanner;