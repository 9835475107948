import React from 'react';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Cookiebanner from './cookiebanner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';



function Footer() {

    
    const [mcshow, setmcShow] = useState(false);
    const [switchState, setSwitchState] = useState(true);    
    const [isSwitchOn, setIsSwitchOn] = useState(false);

  
    
    const onSwitchAction = () => {
        
        if (localStorage.getItem('Performance_cookie').toString() === 'false')
        {            
            setIsSwitchOn(true);
        }

        else
            setIsSwitchOn(false);         

    };

    const handleSave = () => {            
        localStorage.setItem('Performance_cookie', isSwitchOn);
        setSwitchState(true);
        setmcShow(false);
    };

    const handleClose = () => setmcShow(false);



    const GoToTop = () =>{
        window.scrollTo({top : 0, left : 0, behavior : "smooth"});

    };
  

    return (<>
        
        <Modal 
            show={mcshow}           
            backdrop="static"
            keyboard={false}
            centered
            size='xl'   
            onHide={handleClose}
            
        >
            <Modal.Header closeButton>
                <Modal.Title id="managecookies-custom-modal-styling-title">                    
                    <img src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Hertz_247_logo.png" className="img-fluid" alt="Hertz24x7" width={200} /> &nbsp; Manage Cookies
                </Modal.Title>
            </Modal.Header>
            <Modal.Body >
                When you visit any website, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. The information does not usually directly identify you, but it can give you a more personalized web experience. Because we respect your right to privacy, you can choose not to allow some types of cookies. Click on the different category headings to find out more and change our default settings.
                <Form> 
                    <div className="accordion p-4" id="accordionMgCookie">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-mgc_echeading">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-mgc_eccollapse" aria-expanded="false" aria-controls="flush-mgc_eccollapse">
                                    <b> <Form.Check
                                        disabled
                                        type="switch"
                                        label="Essential"
                                        id="disabled-custom-switch"
                                        defaultChecked={switchState}
                                    />
                                    </b>
                                </button>
                            </h2>
                            <div id="flush-mgc_eccollapse" className="accordion-collapse collapse" aria-labelledby="flush-mgc_echeading" data-bs-parent="#accordionMgCookie">
                                Some cookies are vital for the operation of our websites. Without them you would not be able to move through the website and use some of its features. Essential cookies enable information you provide and decisions you take regarding your rental to be remembered from page to page in the booking process. Because these cookies are essential, we do not enable you to choose to disable them on our website
                                <div className="accordion-body">
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Cookie Name</th>
                                                <th>Description</th>                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>dGroup</td>
                                                <td>Remembers what device the site is being accessed from (PC, tablet, phone) to display the appropriate content</td>                                                
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>incap_ses_*</td>
                                                <td>Used to link HTTP request to a session.</td>                                                
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>nlbi_*</td>
                                                <td>This cookie is used to filter out malicious requests.The information generated cannot identify you as an individual.</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>visid_incap_*</td>
                                                <td>Used to check that the user accepts cookies and is not a robot.</td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>DC-Cookie</td>
                                                <td>Used to route you to correct server during your whole visit.</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>                      

                        <div className="accordion-item">                    
                            <h2 className="accordion-header" id="flush-mgcheading2">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-mgccollapse2" aria-expanded="false" aria-controls="flush-mgccollapse2">
                                    <b>
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            label="Performance Cookies"
                                            onChange={onSwitchAction}
                                            defaultChecked={isSwitchOn}
                                        />
                                    </b>
                                </button>
                            </h2>
                            <div id="flush-mgccollapse2" className="accordion-collapse collapse" aria-labelledby="flush-mgcheading2" data-bs-parent="#accordionMgCookie">
                                We use performance cookies to monitor website performance and to analyse how our visitors use our websites. This allows us to provide a high-quality experience by quickly identifying and fixing any issues that arise and customising our offering. All information collected is aggregated and therefore anonymous and is only used to improve how our website works.
                                <div className="accordion-body">NA</div>
                            </div>
                        </div>
                    </div>                
                 </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" onClick={handleSave}>
                    Save Changes
                </Button>

            </Modal.Footer>
        </Modal>
      
        <Cookiebanner isVisible={localStorage.getItem('Cookie_acceptordeny')}/>
        <div className="container-fluid p-4 body-font">            
            <div className="row" style={{ backgroundColor: 'lightgray' }}>                
                <div className="col-xs-12 col-md-5 py-md-5" style={{ textAlign: 'left' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Hertz-247-Horizontal-CMYK 1.png" alt='' />
                    <p>Looking for hourly van rental in convenient locations near<br /> you? Hertz 24/7® provides hourly rentals of vans, available<br /> 24 hours a day, 7 days a week, in Europe. We have a large<br /> selection of vans to rent from our retails partners car parks<br /> like B&Q, Costco, Ikea or Homebase..</p>
                </div>
                <div className="col-xs-12 col-md-7 py-md-5" >
                    <div className="row">
                        <div className="col-xs-12 col-md-4 " >
                            <p><b>About Hertz  24/7®  </b></p>
                            <p><NavLink className="nav-link" to="/UK/Howitworks" onClick={GoToTop}>How it works</NavLink></p>
                            <p><NavLink className="nav-link" to="/UK/Locations" onClick={GoToTop}>Locations</NavLink></p>
                            <p><NavLink className="nav-link" to="/UK/BusinessSolution" onClick={GoToTop}>Business Solutions</NavLink></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " >
                            <p><b>Support</b></p>
                            <p><NavLink className="nav-link" to="/UK/FAQ" onClick={GoToTop}>FAQ</NavLink></p>
                            <p><NavLink className="nav-link" to="mailto:hertzuk247@hertz.com">Contact</NavLink></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " >
                            <p><b>More</b></p>                            
                            <p><a className="nav-link" href="https://www.hertz.co.uk/rentacar/navigation/templates/legalView.jsp" target="_blank" rel='noreferrer'>Terms of use</a></p>
                            <p><NavLink className="nav-link" onClick={() => {
                                setmcShow(true);
                                if (localStorage.getItem('Performance_cookie').toString() === 'false')
                                    setIsSwitchOn(false);
                                else
                                    setIsSwitchOn(true);
                            }} style={{ textDecoration: 'none' }}>Manage Cookies</NavLink></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" style={{ backgroundColor: 'lightgray' }}>
                <span className="border border-2"></span>
                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'left' }}>
                    <b>©2024 Hertz 24/7®  . All rights reserved</b>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <div className="row">
                        <div className="col-xs-12 col-md-4">
                            <a href='https://apps.apple.com/gb/app/hertz-24-7-mobility/id6453605078'>
                                <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/AppleAppStore.png" alt='' width={250} />
                            </a>
                        </div>
                        <div className="col-xs-12 col-md-1" >
                            &nbsp;
                        </div>
                        <div className="col-xs-12 col-md-4">
                            <a href='https://play.google.com/store/apps/details?id=com.hertz247.driverApp&hl=en_GB&gl=GB'>
                                <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/GooglePlayStore.png" alt='' width={250} />
                            </a>
                        </div>
                        <div className="col-xs-12 col-md-3" >
                            <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/QR_Small.png" alt='' />
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <b><NavLink className="nav-link" to="https://www.hertz.co.uk/rentacar/privacypolicy/index.jsp?targetPage=privacyPolicyView.jsp" target='_blank' rel='noreferrer'>Privacy & Policy</NavLink></b>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <b><NavLink className="nav-link" to="https://resources.hertz247.ufofleet.com/publishing/documents/247TCUKEN.pdf" target='_blank' rel='noreferrer'>Terms & Condition</NavLink></b>
                        </div>
                        
                    </div>
                    
                </div>
                
            </div>
        </div>

    </>);
}
export default Footer;