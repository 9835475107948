import React from 'react';
import { NavLink } from 'react-router-dom';
import Cookiebanner from './cookiebanner';

function Footer() {

    const GoToTop = () =>{
        window.scrollTo({top : 0, left : 0, behavior : "smooth"});
    };
   

    return (<>
        <Cookiebanner isVisible={localStorage.getItem('Cookie_acceptordeny')}  />
        <div className="container-fluid p-4 body-font">
            <div className="row" style={{ backgroundColor: 'lightgray' }}>
                <div className="col-xs-12 col-md-5 py-md-5" style={{ textAlign: 'left' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Hertz-247-Horizontal-CMYK 1.png" alt='' />
                    <p>Procura uma solução de mobilidade à hora?<br /> A Hertz 24/7® oferece uma seleção de viaturas ideais para transportes de média e grande dimensão. <br />  Disponível em lojas IKEA e Leroy Merlin.</p>
                </div>
                <div className="col-xs-12 col-md-7 py-md-5" >
                    <div className="row">
                        <div className="col-xs-12 col-md-4 " >
                            <p><b>Sobre a Hertz 24/7®  </b></p>
                            <p><NavLink className="nav-link" to="/PT/Howitworks" onClick={GoToTop}>Sobre a Hertz 24/7®</NavLink></p>
                            <p><NavLink className="nav-link" to="/PT/Locations" onClick={GoToTop}>Localizações</NavLink></p>                            
                        </div>
                        <div className="col-xs-12 col-md-4 " >
                            <p><b>Ajuda</b></p>
                            <p><NavLink className="nav-link" to="/PT/FAQ" onClick={GoToTop}>FAQ</NavLink></p>
                            <p><NavLink className="nav-link" to="mailto: customersupport.247@hertz.pt">Contacto</NavLink></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " >
                            <p><b>Mais</b></p>
                            <p><a className="nav-link" href="https://www.hertz.pt/rentacar/navigation/templates/legalView.jsp" target="_blank" rel='noreferrer'>Termos de utilização</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" style={{ backgroundColor: 'lightgray' }}>
                <span className="border border-2"></span>
                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'left' }}>
                    <b>©2024 Hertz 24/7®  . Todos os direitos reservados</b>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <div className="row">
                        <div className="col-xs-12 col-md-4">
                            <a href='https://apps.apple.com/pt/app/hertz-24-7-mobility/id6453605078'>
                                <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/AppleAppStore.png" alt='' width={250} />
                            </a>
                        </div>
                        <div className="col-xs-12 col-md-1" >
                            &nbsp;
                        </div>
                        <div className="col-xs-12 col-md-4">
                            <a href='https://play.google.com/store/apps/details?id=com.hertz247.driverApp&hl=pt_pt&gl=pt'>
                                <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/GooglePlayStore.png" alt='' width={250} />
                            </a>
                        </div>
                        <div className="col-xs-12 col-md-3" >
                            <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/QR_Small.png" alt='' />
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <b><NavLink className="nav-link" to="https://www.hertz.pt/rentacar/privacypolicy/index.jsp?targetPage=privacyPolicyView.jsp" target='_blank' rel='noreferrer'>Privacidade e política</NavLink></b>
                            
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <b><NavLink className="nav-link" to="https://resources.hertz247.ufofleet.com/publishing/documents/247TCPTPT.pdf" target='_blank' rel='noreferrer'>Termos e condições</NavLink></b>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </>);
}
export default Footer;