import { useState } from 'react';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { NavLink } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';


function Cookiebanner(props) {

    const [show, setShow] = useState(true);
    const [mcshow, setmcShow] = useState(false);
    const [switchState, setSwitchState] = useState(true);
    const [isSwitchOn, setIsSwitchOn] = useState(true);

    if (!localStorage.getItem('Cookie_acceptordeny')) {
        localStorage.setItem('Cookie_acceptordeny', null);
        setShow(true);
    }

    if (!localStorage.getItem('Performance_cookie')) {
        localStorage.setItem('Performance_cookie', false);        
    }

    if (!localStorage.getItem('country')) {
        localStorage.setItem('country', 'uk');
    }

    const handleAccept = () => {
        setShow(false);
        localStorage.setItem('Cookie_acceptordeny', 'accepted');
        localStorage.setItem('Performance_cookie', true);
    };

    const handleDeny = () => {
        setShow(false);
        localStorage.setItem('Cookie_acceptordeny', 'denied');
        localStorage.setItem('Performance_cookie', false);
    };

    const onSwitchAction = () => {
      
        if (localStorage.getItem('Performance_cookie') === 'false') {
            setIsSwitchOn(true);
        }

        else
            setIsSwitchOn(false);

    };

    const handleSave = () => {
        localStorage.setItem('Cookie_acceptordeny', 'accepted');
        localStorage.setItem('Performance_cookie', isSwitchOn);
        setShow(false);
        setmcShow(false);
        setSwitchState(true);
    };

    const handleClose = () => setmcShow(false);
   
    if ((props.isVisible === null || props.isVisible === 'null') || localStorage.getItem('country') !== 'uk')
    {
        localStorage.setItem('country', 'uk');

        return (
            <div>
                <Modal
                    show={show}
                    onHide={handleDeny}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='xl'
                    
                >
                    <Modal.Body>
                        We use cookies on our website. You can <b>“Accept”</b>, <b>“Decline”</b> or change your setting anytime at your choice. By selecting <b>“Decline”</b> you only allow the use of essential cookies that make our site work. You may disable these by changing your browser settings, but this may affect how the website functions. By selecting <b>“Accept”</b>, we will set all optional cookies that help us analyse traffic, offer enhanced functionality, as well as personalised content and ads.To find out more about the cookies we use and to decide your optional cookies settings, please select <b>“Manage my Cookies”</b>. For information about how we use the data we collect,please visit our <a href="https://www.hertz.co.uk/rentacar/privacypolicy/index.jsp?targetPage=privacyPolicyView.jsp" target='_blank' rel='noreferrer'>Privacy Policy</a>.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="warning" onClick={handleAccept}>
                            Accept Cookies
                        </Button>
                        <Button variant="warning" onClick={handleDeny}>
                            Decline Cookies
                        </Button>
                        <NavLink className="nav-link" onClick={() => setmcShow(true)} style={{textDecoration : 'none'}}>Manage my Cookies</NavLink> 
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={mcshow}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='xl'
                    onHide={handleClose}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="managecookies-custom-modal-styling-title">
                            <img src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Hertz_247_logo.png" className="img-fluid" alt="Hertz24x7" width={200} /> &nbsp; Manage Cookies
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        When you visit any website, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. The information does not usually directly identify you, but it can give you a more personalized web experience. Because we respect your right to privacy, you can choose not to allow some types of cookies. Click on the different category headings to find out more and change our default settings.
                        <Form>
                            <div className="accordion p-4" id="accordionMgCookie">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-mgc_echeading">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-mgc_eccollapse" aria-expanded="false" aria-controls="flush-mgc_eccollapse">
                                            <b> <Form.Check
                                                disabled
                                                type="switch"
                                                label="Essential"
                                                id="disabled-custom-switch"
                                                defaultChecked={switchState}
                                            />
                                            </b>
                                        </button>
                                    </h2>
                                    <div id="flush-mgc_eccollapse" className="accordion-collapse collapse" aria-labelledby="flush-mgc_echeading" data-bs-parent="#accordionMgCookie">
                                        Some cookies are vital for the operation of our websites. Without them you would not be able to move through the website and use some of its features. Essential cookies enable information you provide and decisions you take regarding your rental to be remembered from page to page in the booking process. Because these cookies are essential, we do not enable you to choose to disable them on our website
                                        <div className="accordion-body">
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Cookie Name</th>
                                                        <th>Description</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>dGroup</td>
                                                        <td>Remembers what device the site is being accessed from (PC, tablet, phone) to display the appropriate content</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>incap_ses_*</td>
                                                        <td>Used to link HTTP request to a session.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>nlbi_*</td>
                                                        <td>This cookie is used to filter out malicious requests.The information generated cannot identify you as an individual.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td>visid_incap_*</td>
                                                        <td>Used to check that the user accepts cookies and is not a robot.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>5</td>
                                                        <td>DC-Cookie</td>
                                                        <td>Used to route you to correct server during your whole visit.</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-mgcheading2">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-mgccollapse2" aria-expanded="false" aria-controls="flush-mgccollapse2">
                                            <b>
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    label="Performance Cookies"
                                                    onChange={onSwitchAction}
                                                    defaultChecked={isSwitchOn}
                                                />
                                            </b>
                                        </button>
                                    </h2>
                                    <div id="flush-mgccollapse2" className="accordion-collapse collapse" aria-labelledby="flush-mgcheading2" data-bs-parent="#accordionMgCookie">
                                        We use performance cookies to monitor website performance and to analyse how our visitors use our websites. This allows us to provide a high-quality experience by quickly identifying and fixing any issues that arise and customising our offering. All information collected is aggregated and therefore anonymous and is only used to improve how our website works.
                                        <div className="accordion-body">NA</div>
                                    </div>
                                </div>
                            </div>   
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="warning" onClick={handleSave}>
                            Save Changes
                        </Button>

                    </Modal.Footer>
                </Modal>
            </div>

            
        );
    }

}

export default Cookiebanner;