import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


function Cookiebanner(props) {

    const [show, setShow] = useState(true);

    if (!localStorage.getItem('Cookie_acceptordeny')) {
        localStorage.setItem('Cookie_acceptordeny', null);
        setShow(true);
    }

    if (!localStorage.getItem('country')) {
        localStorage.setItem('country', 'fr');
    }


    const handleAccept = () => {
        setShow(false);
        localStorage.setItem('Cookie_acceptordeny', 'accepted');
    };

    const handleDeny = () => {
        setShow(false);
        localStorage.setItem('Cookie_acceptordeny', 'denied');
    };


    // console.log(props.isVisible);
    if ((props.isVisible === null || props.isVisible === 'null') || localStorage.getItem('country') !== 'fr') {

        localStorage.setItem('country', 'fr');
        return (
            <div>
                <Modal
                    show={show}
                    onHide={handleDeny}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='xl'
                >
                    <Modal.Body>
                        En sélectionnant <b>"Refuser les cookies"</b>, vous autorisez uniquement l'utilisation des cookies essentiels que nous avons configurés pour faire fonctionner notre site et vous offrir une expérience client exceptionnelle. Vous pouvez les désactiver en modifiant les paramètres de votre navigateur, mais cela peut affecter le fonctionnement du site Web. Nous aimerions également définir des cookies facultatifs qui nous aident à analyser le trafic, à offrir des fonctionnalités améliorées (par exemple, notre assistance par chat), ainsi qu'un contenu et des publicités personnalisés. Ceux-ci ne seront définis que si vous les <b>"acceptez"</b>. Si vous changez d'avis, vous pouvez vous désinscrire à tout moment. Pour en savoir plus sur les cookies que nous utilisons et définir vos paramètres de cookies, veuillez sélectionner <b>"Gérer mes cookies"</b>. Pour plus d'informations sur la manière dont nous utilisons les données que nous collectons, veuillez consulter notre <a href="https://www.hertz.fr/rentacar/privacypolicy/index.jsp?targetPage=privacyPolicyView.jsp" target='_blank' rel='noreferrer'>Politique de confidentialité</a>.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="warning" onClick={handleAccept}>
                            Acceptez
                        </Button>
                        <Button variant="warning" onClick={handleDeny}>
                            Refuser
                        </Button>
                        {/* <NavLink className="nav-link" to="/UK/FAQ">Manage my Cookies</NavLink> */}
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

export default Cookiebanner;