import React from 'react';
import { NavLink } from 'react-router-dom';
import Cookiebanner from './cookiebanner';

function Footer() {
    const GoToTop = () =>{
        window.scrollTo({top : 0, left : 0, behavior : "smooth"});
    };
    return (<>
        <Cookiebanner isVisible={localStorage.getItem('Cookie_acceptordeny')} />
        <div className="container-fluid p-4 body-font">
            <div className="row" style={{ backgroundColor: 'lightgray' }}>
                <div className="col-xs-12 col-md-5 py-md-5" style={{ textAlign: 'left' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Hertz-247-Horizontal-CMYK 1.png" alt='' />
                    <p>Hledáte hodinový pronájem dodávek v lokalitách ve vašem okolí? Hertz 24/7® poskytuje hodinový pronájem dodávek, který je v Evropě k dispozici 24 hodin denně, 7 dní v týdnu. Máme velký výběr dodávek k pronájmu na parkovištích našich maloobchodních partnerů, jako například IKEA.</p>
                </div>
                <div className="col-xs-12 col-md-7 py-md-5" >
                    <div className="row">
                        <div className="col-xs-12 col-md-4 " >
                            <p><b>O službě Hertz 24/7® </b></p>
                            <p><NavLink className="nav-link" to="/CZ/Howitworks" onClick={GoToTop}>Jak pronájem funguje</NavLink></p>
                            <p><NavLink className="nav-link" to="/CZ/Locations" onClick={GoToTop}>Lokality</NavLink></p>
                            <p><NavLink className="nav-link" to="/CZ/BusinessSolution" onClick={GoToTop}>Obchodní řešení</NavLink></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " >
                            <p><b>Podpora</b></p>
                            <p><NavLink className="nav-link" to="/CZ/FAQ" onClick={GoToTop}>Nejčastěji kladené otázky</NavLink></p>
                            <p><NavLink className="nav-link" to="mailto:hertz247@hertz.cz">Kontakt</NavLink></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " >
                            <p><b>Více na</b></p>                            
                            <p><a className="nav-link" href="https://www.hertz.cz/rentacar/navigation/templates/legalView.jsp" target="_blank" rel='noreferrer'>Podmínky použití</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" style={{ backgroundColor: 'lightgray' }}>
                <span className="border border-2"></span>
                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'left' }}>
                    <b>©2024 Hertz 24/7®  . Všechna práva vyhrazena</b>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <div className="row">
                        <div className="col-xs-12 col-md-4">
                            <a href='https://apps.apple.com/cz/app/hertz-24-7-mobility/id6453605078'>
                                <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/AppleAppStore.png" alt='' width={250} />
                            </a>
                        </div>
                        <div className="col-xs-12 col-md-1" >
                            &nbsp;
                        </div>
                        <div className="col-xs-12 col-md-4">
                            <a href='https://play.google.com/store/apps/details?id=com.hertz247.driverApp&hl=cs_CZ&gl=CZ'>
                                <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/GooglePlayStore.png" alt='' width={250} />
                            </a>
                        </div>
                        <div className="col-xs-12 col-md-3" >
                            <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/QR_Small.png" alt='' />
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <b><NavLink className="nav-link" to="https://www.hertz.cz/rentacar/privacypolicy/index.jsp?targetPage=privacyPolicyView.jsp" target='_blank' rel='noreferrer'>Ochrana soukromí a zásady</NavLink></b>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <b><NavLink className="nav-link" to="https://resources.hertz247.ufofleet.com/publishing/documents/247CZ.pdf" target='_blank' rel='noreferrer'>Všeobecné obchodní podmínky</NavLink></b>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </>);
}
export default Footer;