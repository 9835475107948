import React from 'react';
import { NavLink } from 'react-router-dom';
import Cookiebanner from './cookiebanner';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';


function Footer() {

    const [mcshow, setmcShow] = useState(false);
    const [switchState, setSwitchState] = useState(true);
    const [isSwitchOn, setIsSwitchOn] = useState(false);

    const onSwitchAction = () => {

        if (localStorage.getItem('Performance_cookie') === 'false') {
            setIsSwitchOn(true);
        }

        else
            setIsSwitchOn(false);

    };

    const handleSave = () => {
        localStorage.setItem('Performance_cookie', isSwitchOn);
        setSwitchState(true);
        setmcShow(false);
    };

    const handleClose = () => setmcShow(false);

    const GoToTop = () =>{
        window.scrollTo({top : 0, left : 0, behavior : "smooth"});
    };
    return (<>
        
        <Modal
            show={mcshow}
            backdrop="static"
            keyboard={false}
            centered
            size='xl'
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title id="managecookies-custom-modal-styling-title">
                    <img src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Hertz_247_logo.png" className="img-fluid" alt="Hertz24x7" width={200} /> &nbsp;  Gestisci i cookie
                </Modal.Title>
            </Modal.Header>
            <Modal.Body >
                Quando visiti un sito web, questo può memorizzare o recuperare informazioni sul tuo browser, principalmente sotto forma di cookie. Queste informazioni potrebbero riguardare l'utente, le sue preferenze o il suo dispositivo e vengono utilizzate principalmente per far funzionare il sito come previsto. Le informazioni di solito non ti identificano direttamente, ma possono offrirti un'esperienza web più personalizzata. Poiché rispettiamo il tuo diritto alla privacy, puoi scegliere di non consentire alcuni tipi di cookie. Clicca sulle diverse intestazioni delle categorie per saperne di più e modificare le nostre impostazioni predefinite.
                <Form>
                    <div className="accordion p-4" id="accordionMgCookie">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-mgc_echeading">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-mgc_eccollapse" aria-expanded="false" aria-controls="flush-mgc_eccollapse">
                                    <b> <Form.Check
                                        disabled
                                        type="switch"
                                        label="Essenziale"
                                        id="disabled-custom-switch"
                                        defaultChecked={switchState}
                                    />
                                    </b>
                                </button>
                            </h2>
                            <div id="flush-mgc_eccollapse" className="accordion-collapse collapse" aria-labelledby="flush-mgc_echeading" data-bs-parent="#accordionMgCookie">
                                Alcuni cookie sono vitali per il funzionamento dei nostri siti web. Senza di essi non saresti in grado di muoverti all'interno del sito Web e utilizzare alcune delle sue funzionalità. I cookie essenziali consentono di ricordare da una pagina all'altra le informazioni fornite e le decisioni prese in merito al noleggio da una pagina all'altra durante il processo di prenotazione. Poiché questi cookie sono essenziali, non ti consentiamo di scegliere di disabilitarli sul nostro sito web
                                <div className="accordion-body">
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Cookie</th>
                                                <th>Descrizione</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>dGroup</td>
                                                <td>Ricorda da quale dispositivo si accede al sito (PC, tablet, telefono) per visualizzare il contenuto appropriato.</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>incap_ses_*</td>
                                                <td>Utilizzato per collegare le richieste HTTP a una sessione.</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>nlbi_*</td>
                                                <td>Questo cookie viene utilizzato per filtrare le richieste dannose. Le informazioni generate non possono identificare l'utente come individuo.</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>visid_incap_*</td>
                                                <td>Utilizzato per verificare che l'utente accetti i cookie e non sia un robot.</td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>DC-Cookie</td>
                                                <td>Utilizzato per indirizzarti al server corretto durante l'intera visita.</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-mgcheading2">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-mgccollapse2" aria-expanded="false" aria-controls="flush-mgccollapse2">
                                    <b>
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            label="Cookie di prestazione"
                                            onChange={onSwitchAction}
                                            defaultChecked={isSwitchOn}
                                        />
                                    </b>
                                </button>
                            </h2>
                            <div id="flush-mgccollapse2" className="accordion-collapse collapse" aria-labelledby="flush-mgcheading2" data-bs-parent="#accordionMgCookie">
                                Utilizziamo i cookie di prestazione per monitorare le prestazioni del sito Web e per analizzare il modo in cui i nostri visitatori utilizzano i nostri siti Web. Questo ci consente di fornire un'esperienza di alta qualità identificando e risolvendo rapidamente eventuali problemi che si presentano e personalizzando la nostra offerta. Tutte le informazioni raccolte sono aggregate e quindi anonime e vengono utilizzate solo per migliorare il funzionamento del nostro sito web.
                                <div className="accordion-body">NA</div>
                            </div>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" onClick={handleSave}>
                    Salva modifiche
                </Button>

            </Modal.Footer>
        </Modal>

        <Cookiebanner isVisible={localStorage.getItem('Cookie_acceptordeny')} />
        <div className="container-fluid p-4 body-font">
            <div className="row" style={{ backgroundColor: 'lightgray' }}>
                <div className="col-xs-12 col-md-5 py-md-5" style={{ textAlign: 'left' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Hertz-247-Horizontal-CMYK 1.png" alt='' />
                    <p>Cercate un noleggio di veicoli a ore in località convenienti vicino a voi?<br/> Hertz 24/7® offre il  noleggio di veicoli a ore, disponibili 24 ore <br/>su 24, 7 giorni su 7, in Europa. Abbiamo un'ampia scelta di veicoli da<br/> noleggiare presso i parcheggi dei nostri partner commerciali <br/>come Ikea, Leroy Merlin e Tecnomat.</p>
                </div>
                <div className="col-xs-12 col-md-7 py-md-5" >
                    <div className="row">
                        <div className="col-xs-12 col-md-4 " >
                            <p><b>Informazioni su Hertz 24/7®  </b></p>
                            <p><NavLink className="nav-link" to="/IT/Howitworks" onClick={GoToTop}>Come funziona</NavLink></p>
                            <p><NavLink className="nav-link" to="/IT/Locations" onClick={GoToTop}>Location</NavLink></p>
                            <p><NavLink className="nav-link" to="/IT/BusinessSolution" onClick={GoToTop}>Soluzioni aziendali</NavLink></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " >
                            <p><b>Supporto</b></p>
                            <p><NavLink className="nav-link" to="/IT/FAQ" onClick={GoToTop}>Domande frequenti</NavLink></p>
                            <p><NavLink className="nav-link" to="mailto:hertzit247@hertz.com">Contatti</NavLink></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " >
                            <p><b>Di più</b></p>                            
                            <p><a className="nav-link" href="https://www.hertz.it/rentacar/navigation/templates/legalView.jsp" target="_blank" rel='noreferrer'>Condizioni di utilizzo</a></p>
                            <p><NavLink className="nav-link" onClick={() => {
                                setmcShow(true);
                                if (localStorage.getItem('Performance_cookie').toString() === 'false')
                                    setIsSwitchOn(false);
                                else
                                    setIsSwitchOn(true);
                            }} style={{ textDecoration: 'none' }}>Gestisci i cookie</NavLink></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" style={{ backgroundColor: 'lightgray' }}>
                <span className="border border-2"></span>
                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'left' }}>
                    <b>©2024 Hertz 24/7®  . Tutti i diritti riservati</b>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <div className="row">
                        <div className="col-xs-12 col-md-4">
                            <a href='https://apps.apple.com/it/app/hertz-24-7-mobility/id6453605078'>
                                <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/AppleAppStore.png" alt='' width={250} />
                            </a>
                        </div>
                        <div className="col-xs-12 col-md-1" >
                            &nbsp;
                        </div>
                        <div className="col-xs-12 col-md-4">
                            <a href='https://play.google.com/store/apps/details?id=com.hertz247.driverApp&hl=it_it&gl=it'>
                                <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/GooglePlayStore.png" alt='' width={250} />
                            </a>
                        </div>
                        <div className="col-xs-12 col-md-3" >
                            <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/QR_Small.png" alt='' />
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <b><NavLink className="nav-link" to="https://www.hertz.it/rentacar/privacypolicy/index.jsp?targetPage=privacyPolicyView.jsp" target='_blank' rel='noreferrer'>Privacy e politica aziendale</NavLink></b>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <b><NavLink className="nav-link" to="https://resources.hertz247.ufofleet.com/publishing/documents/247TCITIT.pdf" target='_blank' rel='noreferrer'>Termini e condizioni</NavLink></b>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </>);
}
export default Footer;